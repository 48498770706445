import React from "react";
import {Link} from "react-router-dom";

// material-ui
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import makeStyles from "@material-ui/core/styles/makeStyles";

const style = theme => ({
    footer: {
        bottom: "0",
        borderTop: "2px solid" + theme.palette.primary.main,
        padding: "15px 0",
    },
    a: {
        color: theme.palette.primary.main,
        textDecoration: "none",
        backgroundColor: "transparent",
        fontFamily: "bauserif"
    }
});

export default function Footer(props) {
    const classes = makeStyles(style)();
    const [mentions, setMentions] = React.useState(false);
    return (
        <React.Fragment>
            <Modal open={mentions} onClick={() => {setMentions(false)}}>
                <div style={{position:"absolute", width: "400", backgroundColor: "#0094b2", border: "0px solid #000", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                    <Container component="main" maxWidth="md">
                        <Grid container justify={"center"} style={{padding: "5rem"}}>
                            <Grid item md={12}>
                                <Typography variant={"h3"} style={{color: '#000'}}>Mentions légales</Typography>
                                <br/>
                                SARL CoproTAG au capital de 1 000€<br/>
                                <br/>
                                34 rue du Docteur Abel<br/>
                                26 000 Valence<br/>
                                <br/>
                                Tel: <a href="tel:04 81 16 09 06">04 81 16 09 06</a> – courriel <a href="mailto:contact@CoproTAG.com">contact@CoproTAG.com</a><br/>
                                TVA : FR21880178876<br/>
                                SIRET : 88017887600012 R.C.S. Romans<br/>
                                NAF : 4799A<br/>
                                <br/>
                                Directrice de la Publication :<br/>
                                Mme GOMEZ Sabine<br/>
                                <br/>
                                Hébergement & DPO / RGPD :<br/>
                                M DECHAUNE Julien – <a href={"https://Dromotique.com"}>Dromotique</a><br/>
                                DPO : DPO-89458<br/>
                                13 rue Premier bat A3<br/>
                                26 100 Romans – 06 51 53 88 33<br/>
                                <br/>
                                Ce site ne contient pas de cookies.

                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Modal>
            <footer className={classes.footer}>
                <Typography align="right">&copy; {1900 + new Date().getYear()}{" "}<Link onClick={() => {setMentions(true)}}>Mentions légales</Link>{" "}- Systèmes de suivi 2.0&nbsp;&nbsp;</Typography>
            </footer>
        </React.Fragment>
    )
}