import React from "react";
import {Route, Switch, useHistory, useParams} from "react-router-dom";

import {connect, useStore} from 'react-redux';
import PropTypes from 'prop-types';
import {loadUser, login, tokenConfig} from '../../actions/authentication';
import Login from '../home/components/login'
import axios from "axios";
import Image from 'material-ui-image'
import Grid from "@material-ui/core/Grid";
import head_contact from "../../assets/img/head_contact.png";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import routes from "../home/routes";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";

import Box from "@material-ui/core/Box";
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import theme from "../../theme";
import Modal from '@material-ui/core/Modal';
import Container from "@material-ui/core/Container";

import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import ContactlessOutlinedIcon from '@material-ui/icons/ContactlessOutlined';
import Typography from "@material-ui/core/Typography";
import GridContainer from "../app/components/Grid/Container";
import GridItem from "../app/components/Grid/Item";



const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 320,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


function ScanLayout(props) {
    let { tagID } = useParams();
    const store = useStore()

    const [datas, setDatas] = React.useState({
        loaded: false,
        theme: {
            bgColor: "#ffffff"
        },
        prestataires: []
    });

    const [showLogin, setshowLogin] = React.useState(false)

    const getTagDatas = () => {
        axios.get('https://api.coprotag.com/api/tags/scan/'+ tagID, tokenConfig(store.getState)).then(res => {
            setDatas({
                loaded: true,
                ...res.data
            });
            if (res.data.location_id ==="") {
                getLocations();
            }
        }).catch(err => {
            console.log("Fetch failed")
        });
    }

    React.useEffect(() => {

        store.dispatch(loadUser());
        getTagDatas()


    }, [store.getState]);

    const classes = useStyles();

    const [modalStyle] = React.useState(getModalStyle);


    const [actionsList, setactionsList] = React.useState([]);


    const doChangePresta = (value) => {
        let actions = []
        console.log(datas.prestataires)

        datas.prestataires.map((prop, key) => {
            if (prop.id === value) {
                prop.actions.map((p, k) => {
                    actions.push(<FormControlLabel value={p.id} control={<Checkbox onChange={(event) => handleChangeAction(event, p.id)} style={{color: datas.theme.radio_action_color}}/>} label={p.name} key={k}/>)
                })
            }
        })
        setactionsList(actions);
    };


    const last_presta = () => {
        return "-1"
        let ls = localStorage.getItem("last_prestataire_selected")
        if(ls === null) {
            return "-1"
        }
        return ls;
    }

    const [prestataire, setPrestatairee] = React.useState(last_presta);
    const [observations, setObservations] = React.useState('');
    const [action, setAction] = React.useState([]);

    const [prestaError, setPrestaError] = React.useState(false);
    const [actionError, setActionError] = React.useState(false);

    /* Association TAGs */
    const handleselLocation = (event) => {
        setassociateTag({
            ...associateTag,
            selLocation: event.target.value
        })

    };
    const handledescrLocation = (event) => {
        setassociateTag({
            ...associateTag,
            txtDesc: event.target.value
        })

    };
    const handlenameLocation = (event) => {
        setassociateTag({
            ...associateTag,
            txtName: event.target.value
        })

    };
    const [associateTag, setassociateTag] = React.useState({
        selLocation: "-1",
        txtName: "",
        txtDesc: ""
    });

    const handleValidateAssociate = (event) => {
        event.preventDefault();

        if (associateTag.selLocation !== "-1") {
            axios.patch('https://api.coprotag.com/api/tag/' + tagID + '/', {location: associateTag.selLocation}, tokenConfig(store.getState)).then(res => {
                window.location.reload(false);
            }).catch(err => {
                console.log("Oups, Cannot update the location !")
                console.log(err)
            })
        } else {
            if(associateTag.txtName !== "") {
                axios.post('https://api.coprotag.com/api/location/', {
                    "name": associateTag.txtName,
                    "description": associateTag.txtDesc,
                    "client_isSyndic": true,
                    "enabled": true
                }, tokenConfig(store.getState)).then(res => {
                    console.log(res.data.location.id)
                    axios.patch('https://api.coprotag.com/api/tag/' + tagID + '/', {active:true, location: res.data.location.id}, tokenConfig(store.getState)).then(res => {
                        window.location.reload(false);
                    }).catch(err => {
                        console.log("Oups, Cannot update the location !")
                        console.log(err)
                    })

                }).catch(err => {
                    console.log("Oups, Cannot save the location !")
                    console.log(err)
                })
            }
        }
    };

    /* Affichage des prestataires */
    const handleChangePresta = (event) => {
        setPrestatairee(event.target.value);
        doChangePresta(event.target.value)
    }

    const handleChangeObs = (event) => {
        setObservations(event.target.value);
    };

    const handleChangeAction = (event, id) => {
        console.log(id)
        console.log(event.target.checked)
        var a = action;
        if (event.target.checked === true) {
            a.push(id)
        } else {
            for (var i = 0; i < a.length; i++) {
                if ( a[i] === id) {
                    a.splice(i, 1); i--;
                }
            }
        }
        setAction(a);
        console.log(a)
    };

    const doValidation = (event) => {
        //console.log("Validation du form prestaID:" + prestataire + " actionID: " + action + " => " + observations);
        let canSubmit = true;
        if (prestataire === '-1') {
            setPrestaError(true);
            canSubmit = false;
        } else {
            setPrestaError(false);
        }

        if (actionsList.length && action.length === 0) {
            setActionError(true);
            canSubmit = false;
        } else {
            setActionError(false)
        }

        if (canSubmit) {
            axios.post('https://api.coprotag.com/api/tags/event', {tag: tagID, prestataire, actions:action, observation:observations}).then(res => {
                if(res.status === 200) {
                    //console.log("Success !")
                    localStorage.setItem("last_prestataire_selected", prestataire);
                    window.location.replace(datas.theme.success_url);
                }
            }).catch(err => {
                console.log("Fetch failed")
            });
        }
    }

    const prestaList = (
        datas.prestataires.map((prop, key) => {
            return (<MenuItem key={prop.id} value={prop.id} key={prop.id}>{prop.name}</MenuItem>);
        })
    );

    function getLocations() {
        axios.get('https://api.coprotag.com/api/location', tokenConfig(store.getState)).then(res => {
            let location = [];
            location.push(<MenuItem key={"-1"} value="-1">Créer une Copropriété</MenuItem>)
            res.data.map((obj) => {
                if (obj.description !== "") {
                    location.push(<MenuItem key={obj.id} value={obj.id}>{obj.name + " - (" + obj.description + ")"}</MenuItem>)
                } else {
                    location.push(<MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>)
                }
                return res;
            })

            setlocations({
                loaded: true,
                datas: location
            });

        }).catch(err => {
            console.log("Fetch failed")
            console.log(err)
        });
    }

    const [locations, setlocations] = React.useState({
        loaded: false,
        datas: []
    });

    return (
        <React.Fragment>
            {datas.loaded?
                <div style={{backgroundColor: datas.theme.bgColor, height: "100vh", width: "100vw"}}>
                    <Grid container justify="center" style={{backgroundColor: datas.theme.bgColor}}>
                        <Grid item xs={12} sm={12} md={12}>
                            {/*<Image src={datas.theme.logo} aspectRatio={432/181} style={{margin: "auto",  maxWidth: "850px", width:"90%", maxHeight: "30vh", display: "block"}} alt=""/>*/}
                            {<img src={datas.theme.logo} style={{marginTop: "20px", marginLeft: "auto",   marginRight: "auto",maxWidth: "850px", width:"90%", maxHeight: "30vh", display: "block"}}/>}
                        </Grid>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="30vh"
                            >
                        <form>
                            <Grid item xs={12} sm={12} md={12}>
                            <FormControl className={classes.formControl}>
                                <Select
                                    id="prestataire-select"
                                    value={prestataire}
                                    error={prestaError}
                                    required
                                    onChange={handleChangePresta}>
                                    <MenuItem disabled value="-1">Sélectionnez votre société</MenuItem>
                                    {prestaList}
                                </Select>
                            </FormControl>
                            </Grid>

                            {actionsList.length?
                                <Grid item xs={12} sm={12} md={12}>
                            <FormControl className={classes.formControl} error={actionError}>
                                <FormLabel component="legend">Action réalisée</FormLabel>
                                <RadioGroup aria-label="action" name="action"
                                            onChange={handleChangeAction}>
                                    {actionsList}
                                </RadioGroup>
                            </FormControl></Grid>:""}

                            <Grid item xs={12} sm={12} md={12}>
                            <FormControl className={classes.formControl} size="medium" >
                                <TextField onChange={handleChangeObs} style={{backgroundColor: datas.theme.observ_bgColor}} name="observations" id="observations" fullWidth={true} size="medium" variant="outlined" multiline={true} rows={5} helperText="Avez-vous des observations à formuler ?" />
                            </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl className={classes.formControl} size="medium" >
                                <Button onClick={doValidation}  variant="contained" style={{backgroundColor: datas.theme.submitbtnBgColor, color: datas.theme.submittextcolor}}>{datas.theme.submittext}</Button>
                                </FormControl>
                            </Grid>
                        </form>
                            </Box>
                    </Grid>

                    {datas.location_id !== "" ?"":
                        <React.Fragment>
                        <Modal open={true}>
                            <div className={classes.paper} style={modalStyle}>
                                <Container component="main" maxWidth="md">
                                    <CssBaseline />

                                {props.auth.isAuthenticated?
                                    <React.Fragment>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Avatar className={classes.avatar}>
                                                    <ContactlessOutlinedIcon />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} >
                                                <Typography component="h1" variant="h5">
                                                    Activation d'un TAG
                                                </Typography>
                                                <br/>
                                            </Grid>
                                        </Grid>
                                        <form className={classes.form} noValidate>

                                            <GridContainer justify={"center"}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <FormControl style={{width:"100%"}}>
                                            Sélectionnez une Copropriété existante:
                                            <Select style={{marginTop:"30px"}}
                                                labelId="sel-location-label"
                                                id="sel-location"
                                                name="sel-location"
                                                defaultValue="-1"
                                                label="Séléctionnez une copropriété existante"
                                                value={associateTag.selLocation}
                                                onChange={handleselLocation}>
                                                {locations.datas}
                                            </Select>

                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer justify={"center"}>

                                                <GridItem xs={12} sm={12} md={12}>
                                                    <FormControl style={{width:"100%"}}>
                                                        Créer une nouvelle Copropriété
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="name"
                                                label="Nom de la copropriété"
                                                name="name"
                                                value={associateTag.txtName}
                                                onChange={handlenameLocation}
                                                disabled={associateTag.selLocation==="-1"?false:true}

                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="description"
                                                label="Description de la copropriété"
                                                name="description"
                                                value={associateTag.txtDesc}
                                                onChange={handledescrLocation}
                                                disabled={associateTag.selLocation==="-1"?false:true}
                                            />

                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                                onClick={handleValidateAssociate}
                                            >
                                                Associer le TAG
                                            </Button>

                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                        </form>
                                    </React.Fragment>:
                                    <React.Fragment>

                                        <Grid container>
                                            <Grid item xs={3} sm={3} md={3}>
                                                <Avatar className={classes.avatar}>
                                                    <ContactlessOutlinedIcon />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} >
                                                <Typography component="h1" variant="h5">
                                                    Ce TAG est inactif
                                                </Typography>
                                                <br/>
                                            </Grid>
                                        </Grid>
                                        <Typography>Le TAG que vous venez de scanner n'est pas actif, Il est probable que le TAG n'ai pas été activé par le syndic.<br/><br/>Merci de prendre contact avec celui-ci afin de le faire activer</Typography>
                                        <br/>
                                        <Typography>L'identifiant du TAG à activer est:<br/> {datas.id}</Typography>
                                        <br/><br/>
                                        <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit} onClick={() => {
                                            setshowLogin(true)
                                    }}>Vous êtes le syndic ?<br/>Connectez-vous</Button>

                                        {showLogin?<Login open={showLogin} onClose={() => {
                                            setTimeout(() => {
                                                //store.dispatch(loadUser());
                                                getTagDatas()
                                                setshowLogin(false);
                                            }, 1000);

                                        }}/>:""}
                                    </React.Fragment>
                                }
                                </Container>
                            </div>
                        </Modal>
                        </React.Fragment>}

                </div>:
                <div>Veuillez patienter...</div>}
        </React.Fragment>
    )
}

ScanLayout.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, {login})(ScanLayout);