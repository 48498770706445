import React from 'react';
import ChartistGraph from 'react-chartist';

import GridContainer from "../components/Grid/Container";
import GridItem from "../components/Grid/Item";
import Card from "../components/Card";
import Table from "../components/Table"
import CardHeader from "../components/Card/header";
import CardIcon from "../components/Card/icon";
import Typography from "@material-ui/core/Typography";


import Warning from "@material-ui/icons/Warning";
import DeviceHub from "@material-ui/icons/DeviceHub";
import ShowChart from "@material-ui/icons/ShowChart";
import TrackChanges from "@material-ui/icons/TrackChanges";

import makeStyles from "@material-ui/core/styles/makeStyles";
import CardBody from "../components/Card/body";

import Legend from "chartist-plugin-legend";
import PointLabels from "chartist-plugin-pointlabels";
//import "chartist-plugin-fill-donut";
import moment from '../components/Moment'

import axios from 'axios';

import './dashboard.css'

import { useStore } from 'react-redux'
import {tokenConfig} from "../../../actions/authentication";

import { Redirect } from "react-router-dom";

const style = theme => ({
    cardCategory: {
        color: theme.gray_list[0],
        margin: "0px",
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0"
    },
    cardTitle: {
        color: theme.gray_list[2],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "avenir_book",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: theme.gray_list[1],
            fontWeight: "400",
            lineHeight: "1"
        }
    },
});

function DashboardPage(props) {
    const store = useStore()
    const [datas, setDatas] = React.useState({
        loaded: false,
        scan_history: {
            today: "-",
            lasts: [],
            byMonth: [],
            rankLocation: []
        },
        tags:{
            active: "-",
            total: "-",
            inactive: "-",
            byWeek: []
        },
        locations: {
            active: "-",
            total: "-"
        },
        troubles: {
            total: "-",
            lastWeek: '-',
            last: []
        }
    });

    React.useEffect(() => {
        axios.get('https://api.coprotag.com/api/app/stats', tokenConfig(store.getState)).then(res => {
            /* Let's setup Nombre de scan / 12 month */
            let month = [];
            let values = [];
            res.data.scan_history.byMonth.map((obj) => {
                month.push(obj.date);
                values.push(obj.value);
                return res;
            })
            res.data.graph_lastmonth = {
                labels: month,
                series: [{"name": "Nombre de Scan", "data": values}]}

            month = [];
            values = [];
            let inactif = [];
            res.data.tags.byWeek.map((obj) => {
                console.log(moment.months(obj.date-1) )
                month.push(moment.months(obj.date-1));
                values.push(obj.active);
                inactif.push(obj.inactive);
                return res;
            })

            res.data.graph_tag_status = {
                labels: month,
                series: [
                    {"name": "Actifs", "data": values},
                    {"name": "Inactifs", "data": inactif},
                ]
            }

            month = [];
            values = [];
            res.data.scan_history.rankLocation.map((obj) => {
                month.push(obj.location);
                values.push(obj.percent);
                return res;
            })

            res.data.graph_repartition = {
                labels: month,
                series: values
            }

            res.data.graph_lasttroubles = [];
            res.data.troubles.last.map((obj) => {
                res.data.graph_lasttroubles.push([moment(obj.date).fromNow(), obj.location, obj.prestataire, obj.trouble]);
                console.log(obj.date)
                return res;
            })

            res.data.graph_lastscanevents = [];
            console.log(res.data.scan_history.last)
            res.data.scan_history.last.map((obj) => {
                res.data.graph_lastscanevents.push([moment(obj.created).fromNow(), obj.location_name, obj.presta_name, obj.action_name, obj.observation]);
                return res;
            })

            /* Finally we push datas to the react.useState */
            setDatas({
                loaded: true,
                ...res.data
            });
        }).catch(err => {
            console.log("Fetch failed")
        });
    }, [store.getState]);


    if (!store.getState().auth.isAuthenticated) {
        console.log("Hey, you're not login ! Redirect to HOME")
        return <Redirect to="/"/>
    }


    const Chartist = require("chartist");
    //const legend = require('chartist-plugin-legend');


    const animation = {
            draw: function(data) {
                if(data.type === 'bar') {
                    data.element.animate({
                        y2: {
                            begin: 900,
                            dur: 1400,
                            from: data.y1,
                            to: data.y2
                        }
                    });
                } else if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 900,
                            dur: 1400,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height()-20)
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 380,
                            dur: 1500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
    }


    /* Nombre de scan 12 derniers mois*/
    const nbrescanGraph_options = {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        showArea: true,
        height: '261px',
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        },
        plugins: [
            PointLabels({
                labelOffset: {
                    x: 10,
                    y: -35
                },
                labelClass: 'ct-value',
                labelInterpolationFnc: v=>v?v:"0"
            })
        ],
    };

    /* Status des tags */
    var actifOption = {lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        showArea: true,
        hdigh: 50,

        height: '200px',
        axisY: {
            offset: 70,
            onlyInteger: true,
        },
        chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0
        },
        plugins: [Legend(),
            PointLabels({
                labelOffset: {
                    x: 8,
                    y: -5
                },
                labelClass: 'ct-value',
                labelInterpolationFnc: v=>v?v:"0"
            })],
    };

    var parCoproOptions = {
        high: 50,
        height: '400px',
        labelOffset: 60,
        chartPadding: 85,
    };

    var parCoproOptionsResp = [
        ['screen and (min-width: 640px)', {
            chartPadding: 85,
            height: '400px',
            labelOffset: 50,
            labelInterpolationFnc: function(value) {
                return value;
            }
        }],
        ['screen and (min-width: 1024px)', {
            labelOffset: 35,
            chartPadding: 20,
            height: '400px',
            labelInterpolationFnc: function(value) {
                return value;
            }
        }]
    ];


    const classes = makeStyles(style)();
    return (
        <React.Fragment>
            <GridContainer>

                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="primary">
                                <TrackChanges/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Scans du jour</p><br/>
                            <h1 className={classes.cardTitle}
                                style={{fontWeight: 'bold'}}>{datas.scan_history.today}</h1>
                        </CardHeader>
                        <br/>
                    </Card>
                </GridItem>


                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="green">
                                <ShowChart/>
                            </CardIcon>
                            <p className={classes.cardCategory}>TAGs Actifs</p><br/>
                            <h1 className={classes.cardTitle} style={{fontWeight: 'bold'}}>{datas.tags.active}
                            </h1>
                        </CardHeader>
                        <br/>
                    </Card>
                </GridItem>


                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="pink">
                                <Warning/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Anomalies cette semaine</p><br/>
                            <h1 className={classes.cardTitle}
                                style={{fontWeight: 'bold'}}>{datas.loaded ? datas.troubles.lastWeek : "-"}</h1>
                        </CardHeader>
                        <br/>
                    </Card>
                </GridItem>


                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="yellow">
                                <DeviceHub/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Copropriétés équipées</p><br/>
                            <h1 className={classes.cardTitle}
                                style={{fontWeight: 'bold'}}>{datas.locations.active === datas.locations.total ? datas.locations.active : datas.locations.active + " / " + datas.locations.total}</h1>
                        </CardHeader>
                        <br/>
                    </Card>
                </GridItem>

            </GridContainer>


            <GridContainer>


                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="primary">
                                <TrackChanges/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Nombre de scan</p>
                            <h3 className={classes.cardTitle}><small>Sur les douze derniers mois</small>
                            </h3>
                        </CardHeader>
                        <br/>
                        {datas.loaded ?
                            <ChartistGraph data={datas.graph_lastmonth} options={nbrescanGraph_options}
                                           listener={animation} type='Bar'/> :
                            <Typography>Chargement en cours...</Typography>
                        }
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="green">
                                <ShowChart/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Statut des Tags</p>
                            <h3 className={classes.cardTitle}>
                                {datas.tags.active} <small>actifs</small> {datas.tags.inactive} <small>inactifs</small>
                            </h3>
                        </CardHeader>
                        <CardBody>
                            {datas.loaded ?
                                <ChartistGraph data={datas.graph_tag_status} options={actifOption} listener={animation}
                                               type='Line'/> :
                                <Typography>Chargement en cours...</Typography>
                            }
                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>


            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="primary">
                                <TrackChanges/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Derniers évènements</p>
                        </CardHeader>

                        <CardBody>
                                {datas.loaded ?
                                    <Table
                                        tableHeaderColor="pink"
                                        tableHead={["Date", "Copropriété", "Prestataire", "Action", "Observations"]}
                                        tableData={datas.graph_lastscanevents}
                                    /> : <Typography>Chargement en cours...</Typography>}
                        </CardBody>
                    </Card>
                </GridItem>


                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="pink">
                                <Warning/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Dernières anomalies</p>
                        </CardHeader>
                        <CardBody>
                            {datas.loaded ?
                                <Table
                                    tableHeaderColor="pink"
                                    tableHead={["Date", "Copropriété", "Prestataire", "Anomalie détéctée"]}
                                    tableData={datas.graph_lasttroubles}
                                /> : <Typography>Chargement en cours...</Typography>}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="yellow">
                                <DeviceHub/>
                            </CardIcon>

                            <p className={classes.cardCategory}>Répartition par copropriété</p>
                            <h3 className={classes.cardTitle}><small>Sur le dernier mois</small>
                            </h3>
                        </CardHeader>
                        {datas.loaded ?
                            <ChartistGraph data={datas.graph_repartition} options={parCoproOptions}
                                           responsiveOptions={parCoproOptionsResp} type='Pie'/> :
                            <Typography>Chargement en cours...</Typography>}
                    </Card>
                </GridItem>

            </GridContainer>
        </React.Fragment>

    );
};

export default DashboardPage;