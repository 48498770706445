import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// @material-ui/icons
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";

// core components
import NavbarLinks from "../NavbarLinks";

import style from './styles';

export default function Navbar(props) {
    var classes = style();
    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={classes.container}>
                <Hidden mdUp implementation="css">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
                <div className={classes.flex}>
                    <div />
                </div>
                <Hidden smDown implementation="css">
                    <NavbarLinks />
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Navbar.propTypes = {
    handleDrawerToggle: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object)
};
