import makeStyles from "@material-ui/core/styles/makeStyles";

const style = theme => ({
    wrapper: {
    position: "relative",
        top: "0px",
        height: "100vh"
    },
    mainPanel: {
        [theme.breakpoints.up("md")]: {
            width: "100%"
        },
        overflow: "auto",
        position: "relative",
        transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
        float: "right",
        ...theme.transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    content: {
        marginTop: "160px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 250px)"
    },
    ...theme.container
});

export default makeStyles(style);