import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";

import Card from '../../app/components/Card'
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import gate from '../../../assets/img/shutterstock_438230968.jpg';
import meuf from '../../../assets/img/shutterstock_1122694031.jpg'
import riviere from '../../../assets/img/shutterstock_1030421278.jpg';

import surf from '../../../assets/img/Surf.jpg';
import chien from '../../../assets/img/Chien.jpg';
import flashez from '../../../assets/img/Flashez.jpg';
import suivrez from '../../../assets/img/Suivez.jpg';
import gerez from '../../../assets/img/Gerez.jpg';
import visionnez from '../../../assets/img/VisionnezLaVideo.jpg';
import rayures from '../../../assets/img/rayures.png';


class Home extends React.Component {
    render() {
        return(
            <React.Fragment>
                <Grid container justify="center" style={{marginTop: "60px"}}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Carousel height="1%" showArrows={false} showThumbs={false} infiniteLoop={true} interval={3000} stopOnHover={true} autoPlay={true} statusFormatter={ (c, t) => {return ""}}>
                            <div>
                                <img alt="" src={gate} style={{height: 603}}/>
                                <p className="legend" style={{backgroundColor: "rgba(0, 0, 255, 0)", color: "#ffffff",fontWeight: "bold",  fontSize: "large", bottom: "3vh",   width: "initial",  right: "5vh", left:"initial", opacity: "1", textShadow: "1px 2px 2px black"}}>LE FLASH D'INFORMATION DE VOS PRESTATIONS<br/>AU SEIN DES <span style={{fontWeight: "bold", color: "#0094b2"}}>COPROPRIÉTÉS</span></p>
                            </div>
                            <div>
                                <img alt=""  src={riviere} style={{height: 603}}/>
                                <p className="legend" style={{backgroundColor: "rgba(0, 0, 255, 0)", color: "#ffffff",fontWeight: "bold",  fontSize: "large", bottom: "3vh",   width: "initial",  right: "5vh", left:"initial", opacity: "1", textShadow: "1px 2px 2px black"}}>LE FLASH D'INFORMATION DE VOS PRESTATIONS<br/>AU SEIN DES <span style={{fontWeight: "bold", color: "#0094b2"}}>COPROPRIÉTÉS</span></p>

                            </div>
                            <div>
                                <img alt=""  src={meuf} style={{height: 603}}/>
                                <p className="legend" style={{backgroundColor: "rgba(0, 0, 255, 0)", color: "#ffffff",fontWeight: "bold",  fontSize: "large", bottom: "3vh",   width: "initial",  right: "5vh", left:"initial", opacity: "1", textShadow: "1px 2px 2px black"}}>LE FLASH D'INFORMATION DE VOS PRESTATIONS<br/>AU SEIN DES <span style={{fontWeight: "bold", color: "#0094b2"}}>COPROPRIÉTÉS</span></p>

                            </div>
                        </Carousel>
                    </Grid>
                </Grid>

                <Grid container justify="center">
                    <Grid item xs={12} sm={5} md={4} style={{padding: "30px"}}>
                        <Card style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                                <img height="468px" alt="Flashez" src={flashez}/>
                                <p style={{position: "absolute", top: "110px", right: "30px", fontFamily: "bauserif", color: "#0094b2", fontSize: "72px"}}>F<span style={{color: "#fff"}}>lashez</span></p>
                                <Button style={{color: "#fff", backgroundColor: "#0094b2", position: "absolute",top: "294px", right: "50px"}} component={Link} to="/concept#flashez">EN SAVOIR PLUS</Button>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={5} md={4} style={{padding: "30px"}}>
                        <Card style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <img height="468px" alt="Suivez" src={suivrez}/>
                            <p style={{position: "absolute", top: "235px", right: "10px", fontFamily: "bauserif", color: "#0094b2", fontSize: "72px"}}>S<span style={{color: "#fff"}}>uivez</span></p>
                            <Button style={{color: "#fff", backgroundColor: "#0094b2", position: "absolute",top: "395px", right: "40px"}} component={Link} to="/concept#suivez">CONCEPT</Button>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={10} md={4} style={{padding: "30px"}}>
                        <Card style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <img height="468px" alt="Gerez" src={gerez}/>
                            <p style={{position: "absolute", top: "-65px", marginLeft: "20px", fontFamily: "bauserif", color: "#0094b2", fontSize: "72px"}} >G<span style={{color: "#fff"}}>érez</span></p>
                            <Button style={{color: "#fff", backgroundColor: "#0094b2", position: "absolute",top: "35px", right: "60px"}} component={Link} to="/concept#gerez">COMMENT ?</Button>
                        </Card>
                    </Grid>
                </Grid>

                {/* Ligne 2 */}
                <Grid container >
                    <Grid item xs={12} sm={3} md={8} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <Card style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <img height="468px" alt="Visionnez notre démo" src={visionnez}/>
                            {<Button style={{color: "#fff", backgroundColor: "#0094b2", position: "absolute",top: "355px", right: "110px"}} component={Link} to="/video">Visionnez la Vidéo DÉMO</Button>}
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={5} md={4} style={{paddingLeft: "30px", paddingRight: "50px"}}>
                        <Card>
                            <img height="468px" alt="" src={rayures}/>

                            <p style={{
                                position: "absolute",
                                fontSize: "1.6rem",
                                textAlign: "right",
                                marginLeft: "-90px",
                                width: "100%"}}><span style={{fontFamily: "bauserif"}}>Copro<span style={{color: "#0094b2"}}>TAG</span></span> offre une <b>simplicité<br/>de gestion des prestataires</b><br/>au sein de copropriétés<br/></p>
                            <p style={{position: "absolute", fontSize: "24px", textAlign:"right", top: "140px", marginRight:"80px", marginLeft:"40px"}}>Une <span style={{color:"#0094b2"}}>remontée d'informations facilitée et immédiate</span> qui permet aux copropriétaires et à leur syndic de suivre les passages des prestataires dans tous leurs bâtiments en adéquation avec les contrats votés.</p>
                            <Button style={{color: "#fff", backgroundColor: "#0094b2", position: "absolute",top: "355px", right: "40%"}} component={Link} to="/concept#pourqui">POUR QUI ?</Button>
                        </Card>
                    </Grid>
                </Grid>


                {/* Ligne 3 */}
                <Grid container>
                    <Grid item xs={12} sm={5} md={4} style={{padding: "30px"}}>
                        <Card style={{backgroundColor: "#0094b2", height: "464px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{fontFamily: "bauserif", color: "#ffffff", fontSize: "48px", marginLeft: "20%"}}>Forfaits</p>
                            <p style={{fontSize: "32px", color: "#ffffff", marginLeft: "20%", marginTop: '-20px'}}>
                                À parir de <span style={{fontSize: "52px"}}>9,95€</span><span style={{fontSize: "28px"}}>HT</span><br/>par mois*
                                <br/><span style={{fontSize: "22px", color: "#000000"}}>*(dès 20 TAGs actifs)</span>
                            </p>
                            <Button style={{color: "#0094b2", backgroundColor: "#ffffff", position: "absolute",top: "355px", right: "40%"}} component={Link} to="/forfaits">DECOUVRIR</Button>
                        </Card>
                    </Grid>


                    <Grid item xs={12} sm={5} md={4} style={{padding: "30px"}}>
                        <Card style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <img height="468px" alt="" src={surf}/>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={5} md={4} style={{padding: "30px"}}>
                        <Card style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <img height="468px" alt="Expertise" src={chien}/>
                            <Button component={Link} to="/expertise" style={{color: "#fff", backgroundColor: "#0094b2", position: "absolute",top: "375px", right: "310px"}}>EXPERTISE</Button>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>)
    }
}

export default Home;