import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./style";

export default function CardIcon(props) {
  const classes = styles();
  const { className, children, color, ...rest } = props;
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + "CardHeader"]]: color,
    [className]: className !== undefined
  });
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
}

CardIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "yellow",
      "pink",
      "green"
  ]),
  children: PropTypes.node
};
