import makeStyles from "@material-ui/core/styles/makeStyles";

const style = theme => ({
  card: {
    border: "0",
    marginBottom: "15px",
    marginTop: "10px",
    borderRadius: "6px",
    color: "rgba(" + theme.HEX2RGB(theme.palette.common.black) + ", 0.87)",
    background: theme.palette.common.white,
    width: "100%",
    boxShadow: "0 1px 4px 0 rgba(" + theme.HEX2RGB(theme.palette.common.black) + ", 0.14)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    fontFamily: "avenir_roman",
    minHeight: "110px"
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center"
  },
  cardChart: {
    "& p": {
      marginTop: "0px",
      paddingTop: "0px"
    }
  },
  cardAvatar: {
    "&$cardAvatarProfile img": {
      width: "100%",
      height: "auto"
    }
  },
  cardAvatarProfile: {
    maxWidth: "130px",
    maxHeight: "130px",
    margin: "-50px auto 0",
    borderRadius: "50%",
    overflow: "hidden",
    padding: "0",
    ...theme.boxShadow,
    "&$cardAvatarPlain": {
      marginTop: "0"
    }
  },
  cardAvatarPlain: {
  },
  cardBody: {
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  cardBodyProfile: {
    marginTop: "15px"
  },
  cardHeader: {
    padding: "0.75rem 1.25rem",
    marginBottom: "0",
    borderBottom: "none",
    background: "transparent",
    zIndex: "3 !important",
    "&$cardHeaderPlain,&$cardHeaderIcon,&$cardHeaderStats,&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$yellowCardHeader,&$greenCardHeader,&$pinkCardHeader": {
      margin: "0 15px",
      padding: "0",
      position: "relative",
      color: theme.palette.common.white
    },
    "&:first-child": {
      borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0"
    },
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$yellowCardHeader,&$greenCardHeader,&$pinkCardHeader": {
      "&:not($cardHeaderIcon)": {
        borderRadius: "3px",
        marginTop: "-20px",
        padding: "15px"
      }
    },
    "&$cardHeaderStats svg": {
      fontSize: "36px",
      lineHeight: "56px",
      textAlign: "center",
      width: "36px",
      height: "36px",
      margin: "10px 10px 4px"
    },
    "&$cardHeaderStats i,&$cardHeaderStats .material-icons": {
      fontSize: "36px",
      lineHeight: "56px",
      width: "56px",
      height: "56px",
      textAlign: "center",
      overflow: "unset",
      marginBottom: "1px"
    },
    "&$cardHeaderStats$cardHeaderIcon": {
      textAlign: "right"
    }
  },
  cardHeaderPlain: {
    marginLeft: "0px !important",
    marginRight: "0px !important"
  },
  cardHeaderStats: {
    "& $cardHeaderIcon": {
      textAlign: "right"
    },
    "& h1,& h2,& h3,& h4,& h5,& h6": {
      margin: "0 !important"
    }
  },
  cardHeaderIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$yellowCardHeader,&$greenCardHeader,&$pinkCardHeader": {
      background: "transparent",
      boxShadow: "none"
    },
    "& i,& .material-icons": {
      width: "33px",
      height: "33px",
      textAlign: "center",
      lineHeight: "33px"
    },
    "& svg": {
      width: "24px",
      height: "24px",
      textAlign: "center",
      lineHeight: "33px",
      margin: "5px 4px 0px"
    }
  },
  primaryCardHeader: {
    color: theme.palette.common.white,
    "&:not($cardHeaderIcon)": {
    background:
      "linear-gradient(60deg, " + theme.palette.primary.dark + ", " + theme.palette.primary.main + ")",
  ...theme.priBoxShadow
    }
  },
  warningCardHeader: {
    color: theme.palette.common.white,
    "&:not($cardHeaderIcon)": {
    background: "linear-gradient(60deg, " + theme.palette.warning.light + ", " + theme.palette.warning.dark + ")",
    boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(" + theme.HEX2RGB(theme.palette.warning.main) + ",.4)"
    }
  },
  successCardHeader: {
    color: theme.palette.common.white,
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + theme.palette.success.light + ", " + theme.palette.success.dark + ")",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(" + theme.HEX2RGB(theme.palette.success.main) + ",.4)"
    }
  },
  dangerCardHeader: {
    color: theme.palette.common.white,
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + theme.palette.error.light + ", " + theme.palette.error.dark + ")",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(" + theme.HEX2RGB(theme.palette.error.main) + ",.4)"
    }
  },
  infoCardHeader: {
    color: theme.palette.common.white,
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, " + theme.palette.info.light + ", " + theme.palette.info.dark + ")",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(" + theme.HEX2RGB(theme.palette.info.main) + ",.4)"
    }
  },
  yellowCardHeader: {
    color: theme.gray_list[2],
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, #DDD301, #FBFF00)",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(" + theme.HEX2RGB("#DDD301") + ",.4)"
    }
  },
  greenCardHeader: {
    color: theme.gray_list[4],
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, #3CA73C, #35C835)",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(" + theme.HEX2RGB("#3CA73C") + ",.4)"
    }
  },
  pinkCardHeader: {
    color: theme.gray_list[4],
    "&:not($cardHeaderIcon)": {
      background: "linear-gradient(60deg, #D6002A, #F73FB4)",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(" + theme.HEX2RGB("#D6002A") + ",.4)"
    }
  },

    cardFooter: {
    padding: "0",
    paddingTop: "10px",
    margin: "0 15px 10px",
    borderRadius: "0",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    backgroundColor: "transparent",
    border: "0"
  },
  cardFooterProfile: {
    marginTop: "-15px"
  },
  cardFooterPlain: {
    paddingLeft: "5px",
    paddingRight: "5px",
    backgroundColor: "transparent"
  },
  cardFooterStats: {
    borderTop: "1px solid " + theme.gray_list[10],
    marginTop: "20px",
    "& svg": {
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "16px",
      height: "16px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "16px",
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardFooterChart: {
    borderTop: "1px solid " + theme.gray_list[10]
  },
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$yellowCardHeader,&$greenCardHeader,&$pinkCardHeader": {
      borderRadius: "3px",
      backgroundColor: theme.gray_list[0],
      padding: "15px",
      marginTop: "-20px",
      marginRight: "5px",
      float: "left"
    }
  }

});

export default makeStyles(style);

