import React from 'react';
import {loadUser} from "../../../../actions/authentication";
import store from "../../../../store";

class Title extends React.Component {
    constructor(props) {
        super(props);
        this.titles = [
            "CoproTAG - Flashez > Suivez > Gérez",
            "CoproTAG -\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​",
            "CoproTAG - Flashez\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​",
            "CoproTAG - Flashez >\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​",
            "CoproTAG - Flashez > Suivez​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​",
            "CoproTAG - Flashez > Suivez >​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​\u205f​​​",
        ];
        this.state = {title: 0};
    }
    componentDidMount() {
        this.timerID = setInterval(
            () => this.changeTitle(),
            1000
        );

        /* Restore if user is Login or not */
        store.dispatch(loadUser());
    }
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    changeTitle() {
        document.title = this.titles[this.state.title];
        this.setState({title: this.state.title +1});
        if (this.state.title === this.titles.length ) {
            this.setState({title: 0});
        }
    };

    render() {
        return (
            <React.Fragment />
        )
    }
}

export default Title;