import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../components/Grid/Container";
import GridItem from "../components/Grid/Item";
import Card from "../components/Card";
import CardHeader from "../components/Card/header";
import CardBody from "../components/Card/body";

import Table from "../components/Table"


import Fab from '@material-ui/core/Fab';
import ExploreIcon from '@material-ui/icons/Explore';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';

import React from "react";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputLabel from '@material-ui/core/InputLabel';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from "axios";
import {tokenConfig} from "../../../actions/authentication";
import {useStore} from "react-redux";
import CardIcon from "../components/Card/icon";
import TrackChanges from "@material-ui/icons/TrackChanges";


const styles = theme => ({
    cardCategory: {
        color: theme.gray_list[0],
        margin: "0px",
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0"
    },
    cardTitle: {
        color: theme.gray_list[2],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "avenir_book",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: theme.gray_list[1],
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
});

const useStyles = makeStyles(styles);

export default function TagManagement() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (id) => {
        console.log("DELETE id=> " + id)
        /*console.log(id)
        axios.delete('https://apidev.coprotag.com/api/location/' + id +'/', tokenConfig(store.getState)).then(res => {
            console.log(res)
            getLocations();
        }).catch(err => {
            console.log("Oups, Cannot DELETE Location !")
        });*/

    };

    const handleValidate = () => {
        console.log("Validate !");
        /*let d = {
            name: locForm.name,
            description: locForm.descr,
            enabled: true,
            client_facturation_syndic: true
        }
        axios.post('https://api.coprotag.com/api/location/', d, tokenConfig(store.getState)).then(res => {
            getLocations();
            handleClose();
        }).catch(err => {
            console.log("Oups, Cannot save the location !")
            console.log(err)
            setlocForm({
                name: "",
                descr: "",
                facturation: -1,
            })
        })*/
    };

    const [locForm, setlocForm] = React.useState({
        name: "",
        descr: "",
        facturation: -1,
    });

    const [Tags, setTags] = React.useState({
        loaded: false,
        associated: [],
        spare: []
    });

    const handleChange = (event) => {
        console.log(event)
        /*if (event.target.name === 'facturation') {
            setlocForm(
                {
                    ...locForm,
                    facturation: event.target.value
                }
            );
        } else if (event.target.name === 'name') {
            setlocForm(
                {
                    ...locForm,
                    name: event.target.value
                }
            );
        } else if (event.target.name === 'descr') {
            setlocForm(
                {
                    ...locForm,
                    descr: event.target.value
                }
            );
        }

        console.log(locForm)*/
    };

    const store = useStore()

    function getTags() {
        axios.get('https://api.coprotag.com/api/tag', tokenConfig(store.getState)).then(res => {
            let associated = [];
            let spare = [];
            res.data.map((obj) => {
                if (obj.location !== null) {
                    associated.push([
                        <React.Fragment>
                            {/*<Fab color="primary" aria-label="modify" align="right">
                                <Button onClick={handleClickOpen}><EditIcon style={{fill: "white"}}/></Button>
                            </Fab>
                            {" "}
                            <Fab color="primary" aria-label="delete" align="right">
                                <Button onClick={() => handleDelete(obj.id)}><DeleteIcon
                                    style={{fill: "white"}}/></Button>
                            </Fab>*/}
                        </React.Fragment>,
                        obj.location_name,
                        obj.description,
                        obj.active ? <CheckIcon/> : <BlockIcon/>,
                        obj.scan_count_last_month,
                        obj.scan_count
                    ])
                } else {
                    spare.push(obj.id)
                }
                return res;
            })

            setTags({
                loaded: true,
                associated: associated,
                spare: spare
            });

        }).catch(err => {
            console.log("Fetch failed")
            console.log(err)
        });
    }

    React.useEffect(() => {
        getTags();
    }, [store.getState]);


    return (
        <React.Fragment>
            <GridContainer>
                <GridItem xs={12} sm={4} md={3}>
                    <Card>
                        <CardHeader stats icon>
                            <CardIcon color="primary">
                                <TrackChanges/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Mes Tags non afféctés</p><br/>
                            <h1 className={classes.cardTitle}
                                style={{fontWeight: 'bold'}}>{Tags.loaded ? Tags.spare.length : "-"}</h1>
                        </CardHeader>
                        <br/>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Mes TAGs</h4>
                            <p className={classes.cardCategoryWhite}>
                                Gérer mes TAGs
                            </p>
                        </CardHeader>
                        <CardBody>

                            {Tags.loaded?<Table
                                tableHeaderColor="primary"
                                tableHead={["", "Nom de la Copropriété", "Emplacement", "Actif", "Nombre de Scans - 30 jours glissant", "Nombre de Scans total"]}
                                tableData={Tags.associated}
                            />:"Loading..."}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            {/* <Fab color="primary" aria-label="add" align="right">
                <Button onClick={handleClickOpen}><ExploreIcon style={{fill: "white"}}/></Button>
            </Fab>*/}

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Affecter un TAG à une copropriété</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Informations concernant la copropriété
                    </DialogContentText>

                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nom de la copropriété"
                            type="text"
                            fullWidth
                            name="name"
                            value={locForm.name}
                            onChange={handleChange}
                        />
                        <DialogContentText>
                            -
                        </DialogContentText>
                        <TextField
                            id="descre"
                            label="Description"
                            type="text"
                            fullWidth
                            name="descr"
                            value={locForm.descr}
                            onChange={handleChange}
                        />

                        <DialogContentText>
                        </DialogContentText>

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="facturation"
                            value={locForm.facturation}
                            onChange={handleChange}
                        >
                            <MenuItem value={-1}>Entitée de facturation</MenuItem>
                            <MenuItem value={0}>Le Syndic</MenuItem>
                            <MenuItem value={1}>La Copropriété</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleValidate} color="primary">
                        Creer la copropriété
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
