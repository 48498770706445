import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import routes from "./routes";

import Navbar from "./components/navbar";
import Footer from "../../components/footer";
import Sidebar from "./components/sidebar";

import styles from "./styles";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {loadUser, login} from "../../actions/authentication";
import store from "../../store";



const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
                return (
                    <Route
                        path={"/app" + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
        })}
        <Redirect from="/app" to="/app/dashboard" />
    </Switch>
);

function AppLayout(props) {
    // styles
    const classes = styles();
    const mainPanel = React.createRef();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                logo="https://api.CoproTAG.com/logo?animated=1&copro_color=0094b2&tag_color=ffffff&baseline=1"
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                {...props}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
                <Navbar
                    routes={routes}
                    handleDrawerToggle={handleDrawerToggle}
                    {...props}
                />
                <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes}</div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

AppLayout.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, {login})(AppLayout);