import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import './assets/fonts/AvenirLTStd-Roman.otf'

let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#58c5e4',
            main: '#0094b2',
            dark: '#006682',
            contrastText: '#fff',
        },
        secondary: {
            light: '#cfff95',
            main: '#9ccc65',
            dark: '#6b9b37',
            contrastText: '#000',
        },
    },
    typography: {
        fontFamily: "avenir_roman",
        fontWeight: "300",
        lineHeight: "1.5em",
    },
    HEX2RGB: function (input) {
        input = input + "";
        input = input.replace("#", "");
        let hexRegex = /[0-9A-Fa-f]/g;
        if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
            throw new Error("input is not a valid hex color.");
        }
        if (input.length === 3) {
            let first = input[0];
            let second = input[1];
            let last = input[2];
            input = first + first + second + second + last + last;
        }
        input = input.toUpperCase(input);
        let first = input[0] + input[1];
        let second = input[2] + input[3];
        let last = input[4] + input[5];
        return (
            parseInt(first, 16) +
            ", " +
            parseInt(second, 16) +
            ", " +
            parseInt(last, 16)
        );
    },

    gray_list: [
        "#111","#222", "#333", "#444", "#555", "#666", "#777", "#888", "#999", "#aaa", "#bbb", "#ccc", "#ddd", "#eee"
    ],
});

theme = responsiveFontSizes(theme);
export default theme;