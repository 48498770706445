import React from "react";
import GridContainer from "../components/Grid/Container";
import GridItem from "../components/Grid/Item";
import Card from "../components/Card";
import CardHeader from "../components/Card/header";
import CardIcon from "../components/Card/icon";
import TrackChanges from "@material-ui/icons/TrackChanges";
import {makeStyles} from "@material-ui/core/styles";
import CardBody from "../components/Card/body";
import Table from "../components/Table";
import CustomInput from "../components/Input";
import SelectInput from "@material-ui/core/Select/SelectInput";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import moment from '../components/Moment'
import MomentUtils from '@date-io/moment';
import {Box} from "@material-ui/core";
import {useStore} from "react-redux";
import axios from "axios";
import {tokenConfig} from "../../../actions/authentication";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import AddIcon from "@material-ui/icons/Add";

const styles = theme => ({
    cardCategory: {
        color: theme.gray_list[0],
        margin: "0px",
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0"
    },
    cardTitle: {
        color: theme.gray_list[2],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "avenir_book",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: theme.gray_list[1],
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },

});

const useStyles = makeStyles(styles);

export default function CreateReport() {
    const classes = useStyles();

    const [selectedDate, handleDateChange] = React.useState(new Date(new Date().getFullYear(), 0, 1));
    const [selectedDateEnd, handleDateChangeEnd] = React.useState(new Date());

    const [prestataires, setprestataires] = React.useState({
        loaded: false,
        datas: []
    });

    const [locations, setlocations] = React.useState({
        loaded: false,
        datas: []
    });

    const [rapport, setRapport] = React.useState({
       date_start: "",
       date_end: "",
       prestataire: "",
       location: "",
    });

    const handleChange = (event) => {
        setRapport({
            ...rapport,
            date_start: selectedDate,
            date_end: selectedDateEnd,
        })
        console.log(event.target.name)
        if (event.target.name === 'select-location') {
            console.log("Change Location")
            console.log(event.target.value)
            setRapport({
                ...rapport,
                location: event.target.value
            })
        } else if (event.target.name ==='select-prestataire') {
            console.log("Change Prestataire")
            setRapport({
                ...rapport,
                prestataire: event.target.value
            })
        }
    };

    const doRapport = (event) => {
        if (rapport.prestataire === '' && rapport.location === '') {
            console.log("Presta ou location non specifié")
            return false;
        }
        if (rapport.prestataire !== '' && rapport.location !== '') {
            console.log("Vous devez choisir soit un prestataire soit une copropriété")
            return false;
        }
        if (selectedDate.toISOString() === selectedDateEnd.toISOString()) {
            console.log("Merci de choisir des dates !=")
            return false;
        }
        if(selectedDateEnd < selectedDate) {
            console.log("La date de fin est inferieur à la date de début")
            return false;
        }
        if(selectedDate > new Date()) {
            console.log("La date de début est dans le futur")
            return false;
        }
        setRapport({
            ...rapport,
            date_end: selectedDateEnd,
            date_start: selectedDate
        })
        let d = {

        }
        if(rapport.prestataire) {
            d.prestataire = rapport.prestataire
        } else {
            d.location = rapport.location
        }
        d.date_start = selectedDate
        d.date_end = selectedDateEnd


        console.log("Click ! ")
        axios.post('https://api.coprotag.com/api/rapport', d, tokenConfig(store.getState)).then(res => {
            return res;
        })
    }

    const store = useStore()
    React.useEffect(() => {
        getLocations();
        getPrestataires();
    }, [store.getState]);



    function getPrestataires() {
        axios.get('https://api.coprotag.com/api/prestataire', tokenConfig(store.getState)).then(res => {
            let prestataire = [];
            res.data.map((obj) => {
                prestataire.push(<MenuItem value={obj.id}>{obj.nom}</MenuItem>)
                return res;
            })

            setprestataires({
                loaded: true,
                datas: prestataire
            });

        }).catch(err => {
            console.log("Fetch failed")
            console.log(err)
        });
    }


    function getLocations() {
        axios.get('https://api.coprotag.com/api/location', tokenConfig(store.getState)).then(res => {
            let location = [];
            res.data.map((obj) => {
                location.push(<MenuItem value={obj.id}>{obj.name}</MenuItem>)
                return res;
            })

            setlocations({
                loaded: true,
                datas: location
            });

        }).catch(err => {
            console.log("Fetch failed")
            console.log(err)
        });
    }

    return (
        <React.Fragment>
            <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Mes Rapports d'activité</h4>
                                <p className={classes.cardCategoryWhite}>
                                    Générer des rapports afin de gérer votre parc de TAGs
                                </p>
                            </CardHeader>
                            <CardBody>

                                <GridContainer justify={"center"}>
                                    <GridItem xs={3} sm={3} md={6}>
                                        <FormControl style={{width:"100%"}}>
                                            Créer un Rapport sur une Copropriété:
                                        <Select
                                            labelId="select-location-label"
                                            id="select-location"
                                            name="select-location"
                                            disabled={rapport.prestataire !== ''}
                                            label="Creer un rapport concernant une Copropriété"
                                            onChange={handleChange}
                                        >
                                            {locations.datas}
                                        </Select>
                                        </FormControl>
                                        <br/> <br/>
                                        <br/> <br/>
                                        <FormControl style={{width:"100%"}}>
                                            Créer un Rapport sur un Prestataire:
                                            <Select
                                                labelId="select-prestataire-label"
                                                id="select-prestataire"
                                                name="select-prestataire"
                                                disabled={rapport.location !== ''}
                                                label="Creer un rapport concernant un préstataire"
                                                onChange={handleChange}
                                            >
                                                {prestataires.datas}
                                            </Select>
                                        </FormControl>
                                        <br/> <br/>
                                        <br/> <br/>
                                    </GridItem>

                                </GridContainer>
                                <GridContainer justify={"center"}>
                                    <GridItem xs={3} sm={3} md={6}>
                                        <FormControl fullWidth={true}>
                                            Séléctionnez une date de début:
                                            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                                            <DatePicker
                                                autoOk
                                                orientation="landscape"
                                                id="date_start"
                                                name="date_start"
                                                variant="static"
                                                value={selectedDate}

                                                onChange={handleDateChange}
                                            />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </GridItem>

                                    <GridItem xs={3} sm={3} md={6}>
                                        <FormControl fullWidth={true}>
                                            Ainsi qu'une date de fin:
                                            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
                                                <DatePicker
                                                    autoOk
                                                    orientation="landscape"
                                                    variant="static"
                                                    name="date_end"
                                                    id="date_end"
                                                    value={selectedDateEnd}

                                                    onChange={handleDateChangeEnd}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justify={"center"}>
                                <GridItem xs={3} sm={3} md={6}>
                                    <Fab color="primary" variant="extended" aria-label="add" align="right">
                                        <Button onClick={doRapport} style={{color: "#ffffff"}}><AddIcon style={{fill: "white"}}/> Creer un rapport</Button>
                                    </Fab>
                                </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
            </GridContainer>
        </React.Fragment>
    )

}