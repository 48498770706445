import makeStyles from "@material-ui/core/styles/makeStyles";

const style = theme => ({
    appBar: {
        backgroundColor: "transparent",
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100%",
        paddingTop: "10px",
        zIndex: "1029",
        color: theme.gray_list[7],
        border: "0",
        borderRadius: "3px",
        padding: "10px 0",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block"
    },
    container: {
        ...theme.container,
        minHeight: "50px"
    },
    flex: {
        flex: 1
    }
});

export default makeStyles(style);