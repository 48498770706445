import Concept from './pages/concept'
import Expertise from './pages/expertise'
import Forfaits from './pages/forfaits'
import Home from "./pages/home"
import Contact from "./pages/contact";
import Demo from "./pages/demo";

const routes = [
    {
        path: "/concept",
        name: "Concept",
        component: Concept
    },{
        path: "/expertise",
        name: "Expertise",
        component: Expertise
    },{
        path: "/forfaits",
        name: "Forfaits",
        component: Forfaits
    },{
        path: "/coprotag-en-demo-video",
        name: "Démonstation vidéo",
        component: Demo
    },{
        path: "/video",
        name: "Démonstation vidéo",
        component: Demo
    },{
        path: "/contact",
        name: "Contact",
        component: Contact
    },{
        path: "/",
        name: "Accueil",
        component: Home
    },
]

export default routes;