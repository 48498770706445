import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {login, tokenConfig} from '../../actions/authentication';

import AppBar from "./components/appbar";
import Title from './components/title';
import Footer from '../../components/footer';

import routes from './routes';

/* Import Styles */
import style from "./styles";
import axios from "axios";
import {AUTH_ERROR, USER_LOADED} from "../../actions/types";


const switchRoutes = (
    <Switch>
        {
            routes.map((prop, key) => {
                return (<Route path={prop.path} component={prop.component} key={key}/>);
            })
        }
    </Switch>
);

function do_activation(props) {
    const search = props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const login = params.get('login');
    const activation_code = params.get('code');

    if(activation_code !== null && login !== null) {
        console.log("Activation needed !");
        axios.post('https://api.coprotag.com/api/auth/activate', {
            email: login,
            activation_number: activation_code
        }).then(res => {

        }).catch(err => {
            console.log("api/auth/activate failed")
            console.log(err)
        });
        return <Redirect to='/'  />
    }
}


function HomeLayout(props) {
    const classes = style();


    return (
        <div className={classes.wrapper}>
            {do_activation(props)}
            <Title />
            <AppBar/>
            <div className={classes.mainPanel}>
                <div className={classes.content}>
                    <div className={classes.container}>
                        {switchRoutes}
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    )
}

HomeLayout.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps, {login})(HomeLayout);