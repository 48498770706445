import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../components/Grid/Item";
import GridContainer from "../components/Grid/Container";
import Table from "../components/Table";
import Card from "../components/Card";
import CardHeader from "../components/Card/header";
import CardBody from "../components/Card/body";
import {useStore} from "react-redux";
import axios from "axios";
import {tokenConfig} from "../../../actions/authentication";
import Typography from "@material-ui/core/Typography";
import moment from '../components/Moment'

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

export default function ScanEvents() {
    const classes = useStyles();


    const store = useStore()
    const [datas, setDatas] = React.useState({
        loaded: false,
        datas: {}
    });

    React.useEffect(() => {
        axios.get('https://api.coprotag.com/api/tags/events', tokenConfig(store.getState)).then(res => {
            /* Finally we push datas to the react.useState */

            let datas = [];
            res.data.map((obj) => {
                datas.push([moment(obj.created).fromNow(), obj.location_name, obj.presta_name, obj.action_name, obj.observation]);
                return res;
            })

            setDatas({
                loaded: true,
                datas: datas
            });
        }).catch(err => {
            console.log("Fetch failed => " + err)
        });
    }, [store.getState]);

    console.log(datas)
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Historique des flashs</h4>
                        <p className={classes.cardCategoryWhite}>
                            Les dernières actions dans vos copropriétés
                        </p>
                    </CardHeader>
                    <CardBody>
                        {datas.loaded?<Table
                            tableHeaderColor="primary"
                            tableHead={["Date", "Copropriété", "Prestataire", "Action", "Observation"]}
                            tableData={datas.datas}
                        />:<Typography>Chargement en cours...</Typography>}
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
