import axios from 'axios';
import {USER_LOADED, USER_LOADING, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS} from "./types";


// Check Token && Load User
export const loadUser = () => (dispatch, getState) => {
    // Set the user loading...
    dispatch({
        type: USER_LOADING
    });

    console.log("Auth with token: ")
    console.log(tokenConfig(getState))
    axios.get('https://api.coprotag.com/api/auth/user', tokenConfig(getState)).then(res => {
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    }).catch(err => {
        console.log("api/auth/user failed")
        console.log(err)
        dispatch({
            type: AUTH_ERROR
        });
    });
}


// Login User
export const login = (email, password) => dispatch => {
    const config = {
        headers: { 'Content-Type': 'application/json'}
    }

    // create body
    const body = JSON.stringify({email, password});

    axios.post('https://api.coprotag.com/api/auth/login', body, config).then(res => {
        console.log("Login Success => " + res.data.user.email)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    }).catch(err => {
        dispatch({
            type: LOGIN_FAIL
        });
    })
}

// Logout User
export const logout = () => (dispatch, getState) => {
    axios.post('https://api.coprotag.com/api/auth/logout', null, tokenConfig(getState)).then(res => {
        dispatch({
            type: LOGOUT_SUCCESS,
            payload: res.data
        });
    }).catch(err => {
        //TODO
        dispatch({
            type: AUTH_ERROR
        });
    })
}

/* Token Management */

export const tokenConfig = getState => {

    //get token from state
    const token = getState().auth.token;
    const config = {
        headers: { 'Content-Type': 'application/json'}
    }
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
}