import React from "react";
import Grid from "@material-ui/core/Grid";
import Image from "material-ui-image";
import flashez from "../../../assets/img/concept_flashez.png";
import suivez from "../../../assets/img/concept_suivez.png";
import gerez from "../../../assets/img/concept_gerez.png";
import carre from "../../../assets/img/concept_carre.png";
import pourqui from "../../../assets/img/concept_pour_qui.png";

import { useRef } from 'react'


class Concept extends React.Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }
    render() {
        return(
            <React.Fragment>
                <Grid container justify="center" style={{marginTop: "60px"}}>
                    <Grid item xs={12} sm={12} md={11} id="flashez">
                        <Image src={flashez} aspectRatio={1914/752} alt="Flashez: CoproTAG vous fournit un QR code unique par copropriété. Le prestataire s’identifie et flashe le QR code présent sur la copropriété dans laquelle il intervient."/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={11} id="suivez">
                        <Image ref={this.myRef} src={suivez} aspectRatio={1914/752} alt="Suivez: CorpoTAG regroupe sur sa plateforme en ligne les informations relatives à la prestation : Identification, moment de l’intervention, type d’intervention, missions accomplies... Vous disposez d’un compte rendu à distance en temps réel."/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={11} id="gerez">
                        <Image src={gerez} aspectRatio={1914/752} alt="Gerez: CoproTAG met à disposition un bilan statistique des prestations effectuées au sein des copropriétés. Les résultats sont confrontés aux contrats établis avec les prestataires et ainsi mettent en avant les anomalies."/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} id="carre">
                        <Image src={carre} aspectRatio={1124/1052} alt="
                        Simplicité: Le prestataire flashe un QR code depuis son mobile
                        Réactivité: L'information est transmise directement au logiciel de gestion CoproTAG
                        Statistiques: Le logiciel génère des statistiques mettant en avant les anomalies détectées et ainsi la non conformité avec les contrat de prestation
                        Traçabilité: Les syndics ainsi que les coproprietaires peuvent consulter les passages des prestataires sur la plateforme"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={11} id="pourqui">
                        <Image src={pourqui} aspectRatio={1914/752} alt="Pour Qui ?
                        Syndics de copropriété: Le contrôle à distance est bien souvent difficile et chronophage surtout lorsque l’on gère une multitude de copropriétés : Fini l’incertitude, bienvenue la confiance
                        Copropriétaires: Vous avez besoin d’un suivi complet, d’une transparence sur les prestations qui ont lieu dans votre résidence et pour lesquelles vous payez des charges : CoproTAG vous montre tout!
                        Les assemblées générales sont plus fluides, les informations sont transmises aux syndics et aux copropriétaires en toute transparence."/>
                    </Grid>
                </Grid>
            </React.Fragment>)
    }
    scrollToMyRef = () => window.scrollTo(0, this.myRef.current.offsetTop)
}

export default Concept;