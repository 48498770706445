import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from "../components/Grid/Container";
import GridItem from "../components/Grid/Item";
import Card from "../components/Card";
import CardHeader from "../components/Card/header";
import CardBody from "../components/Card/body";
import CustomInput from "../components/Input";
import CardFooter from "../components/Card/footer";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const style = theme => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: 'bauserif',
        marginBottom: "3px",
        textDecoration: "none"
    }
});

function UserProfile(props) {
    const classes = makeStyles(style)();
    return (
        <div>
            {console.log(props.auth)}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Votre Profil</h4>
                            <p className={classes.cardCategoryWhite}>Completez votre profil / Gérez les utilisateurs rattachés à votre compte</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={3} sm={3} md={3}>
                                    <CustomInput
                                        labelText="Société"
                                        id="company-disabled"
                                        formControlProps={{
                                            fullWidth: false
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: props.auth.user.nom_client
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={3} md={3}>
                                    <CustomInput
                                        labelText="Votre n° de client"
                                        id="code_client"
                                        formControlProps={{
                                            fullWidth: false
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value: props.auth.user.code_client
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={5}>
                                    <CustomInput
                                        labelText="Adresse Email"
                                        id="email"
                                        inputProps={{value: props.auth.user.email}}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Votre Nom"
                                        id="firstname"
                                        inputProps={{value: props.auth.user.last_name}}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Votre Prénom"
                                        id="lastname"
                                        inputProps={{value: props.auth.user.first_name}}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Votre Adresse postale"
                                        id="street"
                                        inputProps={{value: props.auth.user.addr_client}}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Code Postal"
                                        id="codepostal"
                                        inputProps={{value: props.auth.user.zip_client}}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Ville"
                                        id="ville"
                                        inputProps={{value: props.auth.user.town_client}}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        labelText="Pays"
                                        id="pays"
                                        inputProps={{value: props.auth.user.country_client, disabled: true,}}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary">Sauvegarder mon profil</Button>
                            <Button color="primary">Changer mon mot de passe</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}


UserProfile.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps)(UserProfile);