import makeStyles from "@material-ui/core/styles/makeStyles";

const style = theme => ({
    drawerPaper: {
        border: "none",
        position: "fixed",
        top: "500",
        bottom: "0",
        left: "0",
        zIndex: "1",
        ...theme.boxShadow,
        width: theme.sidebarSize,
        [theme.breakpoints.up("md")]: {
            width: theme.sidebarSize,
            position: "fixed",
            height: "100%"
        },
        [theme.breakpoints.down("sm")]: {
            width: theme.sidebarSize,
            ...theme.boxShadow,
            position: "fixed",
            backgroundColor:"black",
            display: "block",
            top: "0",
            height: "100vh",
            left: "0",
            zIndex: "1032",
            visibility: "visible",
            overflowY: "visible",
            borderTop: "none",
            textAlign: "left",
            paddingRight: "0px",
            paddingLeft: "0",
            transform: `translate3d(${theme.sidebarSize}px, 0, 0)`,
            ...theme.transition
        }
    },
    logo: {
        position: "relative",
        padding: "26px 15px",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",

            height: "1px",
            right: "15px",
            width: "calc(100% - 30px)",
            backgroundColor: "rgba(" + theme.HEX2RGB(theme.gray_list[6]) + ", 0.3)"
        }
    },
    logoLink: {
        //...defaultFont,
        textTransform: "uppercase",
        padding: "5px 0",
        display: "block",
        fontSize: "18px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "30px",
        textDecoration: "none",
        backgroundColor: "transparent",
        "&,&:hover": {
            color: theme.palette.common.white
        }
    },
    logoImage: {
        width: "30px",
        display: "inline-block",
        maxHeight: "30px",
        marginRight: "15px"
    },
    img: {
        width: "240px",
        top: "10px",
        position: "absolute",
        verticalAlign: "middle",
        border: "0"
    },
    background: {
        position: "absolute",
        zIndex: "1",
        height: "100%",
        width: "100%",
        display: "block",
        top: "0",
        left: "0",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            background: theme.palette.common.black,
            opacity: ".8"
        }
    },
    list: {
        marginTop: "20px",
        paddingLeft: "0",
        paddingTop: "0",
        paddingBottom: "0",
        marginBottom: "0",
        listStyle: "none",
        position: "unset",
        fontFamily: "avenir_roman",
    },
    item: {
        position: "relative",
        display: "block",
        textDecoration: "none",
        "&:hover,&:focus,&:visited,&": {
            color: theme.palette.common.white
        }
    },
    itemLink: {
        width: "auto",
        transition: "all 300ms linear",
        margin: "10px 15px 0",
        borderRadius: "3px",
        position: "relative",
        display: "block",
        padding: "10px 15px",
        backgroundColor: "transparent",
        //...defaultFont
    },
    itemIcon: {
        width: "24px",
        height: "30px",
        fontSize: "24px",
        lineHeight: "30px",
        float: "left",
        marginRight: "15px",
        textAlign: "center",
        verticalAlign: "middle",
        color: "rgba(255, 255, 255, 0.9)"
    },
    itemText: {
        //...defaultFont,
        margin: "0",
        lineHeight: "30px",
        fontSize: "14px",
        color: theme.palette.primary.light,
    },
    whiteFont: {
        color: theme.palette.common.white
    },
    sidefocused: {
        backgroundColor: theme.palette.primary.main,
        boxShadow:
            "0 12px 20px -10px rgba(" +
            theme.HEX2RGB(theme.palette.primary.main) +
            ",.28), 0 4px 20px 0 rgba(" +
            theme.HEX2RGB(theme.palette.common.black) +
            ",.12), 0 7px 8px -5px rgba(" +
            theme.HEX2RGB(theme.palette.primary.main) +
            ",.2)",
        "&:hover,&:focus": {
            backgroundColor: theme.palette.primary.main,
            boxShadow:
                "0 12px 20px -10px rgba(" +
                theme.HEX2RGB(theme.palette.primary.main) +
                ",.28), 0 4px 20px 0 rgba(" +
                theme.HEX2RGB(theme.palette.common.black) +
                ",.12), 0 7px 8px -5px rgba(" +
                theme.HEX2RGB(theme.palette.primary.main) +
                ",.2)"
        },

        fontFamily: "avenir_book",
    },
    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        width: "260px",
        zIndex: "4",
        overflowScrolling: "touch"
    },
});

export default makeStyles(style);