import React from "react";
import {Link} from "react-router-dom";

import classNames from "classnames";
// @material-ui/core components
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";

// core components
import Button from "../Button"
import style from "./style";
import {connect } from 'react-redux';
import {logout} from "../../../../actions/authentication";


function AdminNavbarLinks(props) {
  const classes = style();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };


    const { isAuthenticated, user } = props.auth;

  return (
      <React.Fragment>
        <div className={classes.manager}>
            {isAuthenticated ? <Button
              color="transparent"
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={openNotification ? "notification-menu-list-grow" : null}
              aria-haspopup="true"
              onClick={handleClickNotification}
              className={classes.buttonLink}
          >
            <Notifications className={classes.icons} />
              <span className={classes.notifications}>0</span>
            <Hidden mdUp implementation="css">
              <p onClick={handleCloseNotification} className={classes.linkText}>
                Notification
              </p>
            </Hidden>
          </Button>:""}
          <Poppers
              open={Boolean(openNotification)}
              anchorEl={openNotification}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openNotification }) +
                " " +
                classes.popperNav
              }
          >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    id="notification-menu-list-grow"
                    style={{
                      transformOrigin:
                          placement === "bottom" ? "center top" : "center bottom"
                    }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                      <MenuList role="menu">

                          <MenuItem
                              onClick={handleCloseNotification}
                              className={classes.dropdownItem}
                          >
                              Pas de notifications
                          </MenuItem>

                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
            )}
          </Poppers>
        </div>
        <div className={classes.manager}>
          <Button
              color="transparent"
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={openProfile ? "profile-menu-list-grow" : null}
              aria-haspopup="true"
              onClick={handleClickProfile}
              className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>
          <Poppers
              open={Boolean(openProfile)}
              anchorEl={openProfile}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openProfile }) +
                " " +
                classes.popperNav
              }
          >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    id="profile-menu-list-grow"
                    style={{
                      transformOrigin:
                          placement === "bottom" ? "center top" : "center bottom"
                    }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseProfile}>
                      <MenuList role="menu">
                          {isAuthenticated ? <MenuItem
                            onClick={handleCloseProfile}
                            className={classes.dropdownItem}
                            component={Link}
                            to="/app/dashboard"
                        >
                          Mon espace CoproTAG
                        </MenuItem>:<MenuItem
                              onClick={handleCloseProfile}
                              className={classes.dropdownItem}
                              component={Link}
                              to="/login"
                          >
                              Me connecter
                          </MenuItem>}


                          {isAuthenticated ? <MenuItem
                            onClick={handleCloseProfile}
                            component={Link}
                            to="/app/user"
                            className={classes.dropdownItem}
                        >
                          Mon Profil
                        </MenuItem>:<MenuItem
                              onClick={handleCloseProfile}
                              component={Link}
                              to="/register"
                              className={classes.dropdownItem}
                          >
                              Creer un compte
                          </MenuItem>}

                          {isAuthenticated ? <Divider light />:""}
                          {isAuthenticated ? <MenuItem
                            onClick={props.logout}
                            component={Link}
                            to="/"
                            className={classes.dropdownItem}
                        >
                          Me déconnecter
                        </MenuItem>:""}

                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
            )}
          </Poppers>
        </div>
      </React.Fragment>
  );
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {logout})(AdminNavbarLinks);
