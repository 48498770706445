// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import TrackChanges from '@material-ui/icons/TrackChanges';
import PinDrop from '@material-ui/icons/PinDrop';
import TrendingUp from '@material-ui/icons/TrendingUp';
import Receipt from '@material-ui/icons/Receipt';

// Pages for Application
import DashboardPage from "./pages/dashboard";
import UserProfilePage from "./pages/userProfile";
import ScanEventPage from "./pages/scanEvents";
import LocationsPage from "./pages/locations";
import TagMgntPage from './pages/tagManagement';
import CreateReportPage from './pages/createReport';


const router = [
    {
        path: "/dashboard",
        name: "Mon Espace CoproTAG",
        icon: Dashboard,
        component: DashboardPage
    },
    {
        path: "/events",
        name: "Historique des flashs",
        icon: EmojiPeople,
        component: ScanEventPage
    },
    {
        path: "/mylocations",
        name: "Gérer mes Copropriétés",
        icon: PinDrop,
        component: LocationsPage
    },
    {
        path: "/mytags",
        name: "Gérer mes TAGs",
        icon: TrackChanges,
        component: TagMgntPage
    },
    {
        path: "/rapports",
        name: "Générer un Rapport",
        icon: TrendingUp,
        component: CreateReportPage
    },
    {
        path: "/user",
        name: "Mon Profil",
        icon: Person,
        component: UserProfilePage
    }/*,
    {
        path: "/bill",
        name: "Mes factures",
        icon: Receipt,
        component: UserProfilePage
    }*/
];
export default router;
