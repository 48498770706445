import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../components/Grid/Container";
import GridItem from "../components/Grid/Item";
import Card from "../components/Card";
import CardHeader from "../components/Card/header";
import CardBody from "../components/Card/body";

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';


import React from "react";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from "axios";
import {tokenConfig} from "../../../actions/authentication";
import {useStore} from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import {CheckBox} from "@material-ui/icons";



const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

const recurenceToString = (value, unit) => {
    switch (unit) {
        case 0:
            return "Pas de récurrence"
        case 1:
            return value + " fois par jours sur n'importe quel TAG scanné"
        case 2:
            return value + " fois par semaines sur n'importe quel TAG scanné"
        case 3:
            return value + " fois par quinzaines sur n'importe quel TAG scanné"
        case 4:
            return value + " fois par mois sur n'importe quel TAG scanné"
        case 5:
            return value + " fois par trimestres sur n'importe quel TAG scanné"
        case 6:
            return value + " fois par semetres sur n'importe quel TAG scanné"
        case 7:
            return value + " fois par ans sur n'importe quel TAG scanné"

        case 8:
            return value + " fois par jours sur l'ensemble des TAGs de la copropriété"
        case 9:
            return value + " fois par semaines sur l'ensemble des TAGs de la copropriété"
        case 10:
            return value + " fois par quinzaines sur l'ensemble des TAGs de la copropriété"
        case 11:
            return value + " fois par mois sur l'ensemble des TAGs de la copropriété"
        case 12:
            return value + " fois par trimestres sur l'ensemble des TAGs de la copropriété"
        case 13:
            return value + " fois par semetres sur l'ensemble des TAGs de la copropriété"
        case 14:
            return value + " fois par ans sur l'ensemble des TAGs de la copropriété"

        default:
            return "Oups, cette intervalle est inconnue"
    }
}

export default function Locations() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openPrestaDialog, setOpenPrestaDialog] = React.useState(false);
    const [openActionDialog, setOpenActionDialog] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenPrestaDialog = (location) => {
        setOpenPrestaDialog(true);
        setprestaForm({
            ...prestaForm,
            location: location
        })
    };

    const handleClickActionOpen = (location, prestataire) => {
        console.log(location, prestataire)
        setOpenActionDialog(true);
        setActionForm({
            ...actionForm,
            location: location,
            prestataire: prestataire
        })
    };

    const handleClose = () => {
        setOpen(false);

        setlocForm({
            title: "Créer une copropriété",
            id:"",
            name: "",
            descr: ""
        })
    };

    const handleClosePresta = () => {
        setOpenPrestaDialog(false);

        setprestaForm({
            title: "Créer un prestataire",
            id: "",
            siren: "",
            name: "",
            name_lock: false,
            type_presta: -1,
            location: ""
        })
    }

    const handleCloseAction = () => {
        setOpenActionDialog(false);

        setActionForm({
            title: "Créer une action",
            prestataire: "",
            location: "",
            id:"",
            nom_error: false,
            nom: "",
            type_recurrence: 0,
            type_recurrence_error: false,
            qty:0,
            qty_error: false,
            qty_disabled: true
        })
    };


    const handleDeleteLocation = (id) => {
        console.log("DELETE id=> " + id)
        console.log(id)
        axios.delete('https://api.coprotag.com/api/location/' + id +'/', tokenConfig(store.getState)).then(res => {
            console.log(res)
            getLocations();
        }).catch(err => {
            console.log("Oups, Cannot DELETE Location !")
        });

    };

    const handleDeletePrestataire = (prestataire_id, location_id) => {
        console.log("DELETE id=> " + prestataire_id, location_id)
        const d = {
            ...tokenConfig(store.getState),
            data: {location: location_id},
        }
        axios.delete('https://api.coprotag.com/api/prestataire/' + prestataire_id +'/',  d).then(res => {
            console.log(res)
            getLocations();
        }).catch(err => {
            console.log("Oups, Cannot DELETE Prestataire !")
        });

    };


    const handleDeleteAction = (action) => {
        console.log("DELETE id=> " + action.id)
        const d = {
            ...tokenConfig(store.getState),
            data: {},
        }
        axios.delete('https://api.coprotag.com/api/action/' + action.id +'/',  d).then(res => {
            console.log(res)
            getLocations();
        }).catch(err => {
            console.log("Oups, Cannot DELETE Prestataire !")
        });

    };

    const handleValidate = () => {
        console.log("Validate !");
        console.log(locForm)
        let d = {
            name: locForm.name,
            description: locForm.descr,
            enabled: true
        }
        if(locForm.id === "") {
            axios.post('https://api.coprotag.com/api/location/', d, tokenConfig(store.getState)).then(res => {
                getLocations();
                handleClose();
                }).catch(err => {
                console.log("Oups, Cannot save the location !")
                console.log(err)
            })
            } else {
            axios.patch('https://api.coprotag.com/api/location/' + locForm.id + '/', d, tokenConfig(store.getState)).then(res => {
                getLocations();
                handleClose();
            }).catch(err => {
                console.log("Oups, Cannot update the location !")
                console.log(err)
            })

        }
    }

    const [locForm, setlocForm] = React.useState({
        title: "Créer une copropriété",
        id: "",
        name: "",
        descr: ""
    });

    const [prestaForm, setprestaForm] = React.useState({
        title: "Créer un prestataire",
        id: "",
        siren: "",
        siren_error: false,
        name: "",
        name_lock: false,
        type_presta: -1,
        type_presta_error: false,
        location: ""
    });

    const [actionForm, setActionForm] = React.useState({
        title: "Créer une action",
        prestataire: "",
        location: "",
        id: "",
        nom_error: false,
        nom: "",
        type_recurrence: 0,
        type_recurrence_error: false,
        qty:0,
        qty_error: false,
        qty_disabled: true

    });

    const handleValidatePresta = () => {
        let d = {
            type_prestataire: prestaForm.type_presta,
            siret: prestaForm.siren,
            nom: prestaForm.name,
            location: prestaForm.location

        }
        console.log("Validate prestataire !");
        console.log(d)
        if (prestaForm.type_presta === -1) {
            setprestaForm({
                ...prestaForm,
                type_presta_error: true,
            })
        }

        if(prestaForm.id === "") {
            axios.post('https://api.coprotag.com/api/prestataire/', d, tokenConfig(store.getState)).then(res => {
                handleClosePresta();
                getLocations();
            }).catch(err => {
                console.log("Oups, Cannot save the prestataire !")
                console.log(err)
            })
        }
    }

    const handleValidateAction = () => {
        if (actionForm.nom === '') {
            setActionForm({
                ...actionForm,
                nom_error: true
            })
            return false;
        }
        if (actionForm.type_recurrence !== 0) {
            if (actionForm.qty === 0) {
                setActionForm({
                    ...actionForm,
                    qty_error: true
                })
                return false;
            } else {
                setActionForm({
                    ...actionForm,
                    qty_error: false
                })
            }
        }

        let d = {
            nom: actionForm.nom,
            recurence_count: actionForm.qty,
            prestataire: actionForm.prestataire,
            location: actionForm.location,
            recurence_unit: actionForm.type_recurrence
        }

        if (actionForm.id === "") {
            axios.post('https://api.coprotag.com/api/action/', d, tokenConfig(store.getState)).then(res => {
                getLocations();
                handleCloseAction();
            }).catch(err => {
                console.log("Oups, Cannot save the action !")
                console.log(err)
            })
        } else {
            axios.patch('https://api.coprotag.com/api/action/' + actionForm.id + '/', d, tokenConfig(store.getState)).then(res => {
                getLocations();
                handleCloseAction();
            }).catch(err => {
                console.log("Oups, Cannot update the action !")
                console.log(err)
            })
        }

    }

    const [Location, setLocation] = React.useState({
        loaded: false,
        datas: []
    });

    const handleChange = (event) => {
        console.log(event)
        if (event.target.name === 'name') {
            setlocForm(
                {
                    ...locForm,
                    name: event.target.value
                }
            );
        } else if (event.target.name === 'descr') {
            setlocForm(
                {
                    ...locForm,
                    descr: event.target.value
                }
            );
        }

        console.log(locForm)
    };

    const handlePresta = (event) => {

        if(event.target.name === 'type_presta') {
            setprestaForm({
                ...prestaForm,
                type_presta: event.target.value,
                type_presta_error: false,
            })
        }
        else if(event.target.name === 'siren') {
            if (/^\d+$/.test(event.target.value)) {
                setprestaForm({
                    ...prestaForm,
                    siren: event.target.value,
                    name_lock: false,
                    siren_error: true
                })
            } else if (event.target.value === '') { /* quand on efface tout le field */
                setprestaForm({
                    ...prestaForm,
                    siren: event.target.value,
                    siren_error: false
                })
            } else {
                console.log("Char'"+event.target.value+"' not a number")
            }

            /* on check la longueur, si c'est assez long, on fait un query pour récupérer les infos de la base SIRENE */
            if (event.target.value.length === 9 | event.target.value.length ===14) {
                console.log("Make Query !")
                axios.post('https://api.coprotag.com/api/search_prestataire', {'siren': event.target.value}, tokenConfig(store.getState)).then(res => {
                    console.log(res);
                    if (res.data.response === "OK")
                    setprestaForm({
                        ...prestaForm,
                        siren: res.data.siren,
                        name: res.data.name,
                        name_lock: true,
                        siren_error: false
                    })
                })
            }
        } else if(event.target.name === 'name') {
            console.log("Update name");
            setprestaForm({
                ...prestaForm,
                name: event.target.value
            })
        }
    };

    const handleAction = (event) => {
        if (event.target.name === 'select_recurrence') {
            if (event.target.value === 0) {
                setActionForm({
                    ...actionForm,
                    qty_disabled:true,
                    qty:0
                })
            } else {
                setActionForm({
                    ...actionForm,
                    qty_disabled:false,
                    type_recurrence:event.target.value
                })
            }
        } else if (event.target.name === 'qty') {
            if(event.target.value >= 0) {
                setActionForm({
                    ...actionForm,
                    qty: event.target.value
                })
            }
        } else if(event.target.name === 'nom') {
            if (event.target.value === '') {
                setActionForm({
                    ...actionForm,
                    nom_error: true,
                    nom: event.target.value
                })
            } else {
                setActionForm({
                    ...actionForm,
                    nom_error: false,
                    nom: event.target.value
                })
            }
        }

    }

    const handleModifyLocation = (obj) => {
        console.log("Modify  id=> " + obj)
        /*
        axios.delete('https://apidev.coprotag.com/api/location/' + id +'/', tokenConfig(store.getState)).then(res => {
            console.log(res)
            getLocations();
        }).catch(err => {
            console.log("Oups, Cannot DELETE Location !")
        });*/
        console.log(obj)
        setlocForm({
            title: "Modifier la Copropriété " + obj.name,
            id: obj.id,
            name: obj.name,
            descr: obj.description
        })
        handleClickOpen()
    };

    const handleModifyAction = (location, prestataire, action) =>  {
        console.log(action)
        setActionForm({
            ...actionForm,
            title: "Modifier l'action " + action.nom,
            prestataire: prestataire,
            location: location,
            nom_error: false,
            nom: action.nom,
            id: action.id,
            type_recurrence: action.recurence_unit,
            type_recurrence_error: false,
            qty: action.recurence_count,
            qty_error: false,
            qty_disabled: false
        })
        setOpenActionDialog(true);

    }

    const store = useStore()

    function getLocations() {
        axios.get('https://api.coprotag.com/api/location', tokenConfig(store.getState)).then(res => {
            let d = []
            res.data.map((obj, index) => {
                d.push(<RowLocation key={obj.id} idx={index} location={obj}/>)
            })
            setLocation({loaded: true, datas: d})

        }).catch(err => {
            console.log("Fetch failed")
            console.log(err)
        });
    }

    React.useEffect(() => {
        getLocations();
    }, [store.getState]);


    const useRowStyles = makeStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
    });

    function RowAction(props) {
        const { location_id, prestataire_id, action } = props;

        const classes = useRowStyles();
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell colSpan={2}>
                        <div style={{marginTop: "5px", marginBottom: "5px"}}>
                            <Fab color="primary" variant="extended" aria-label="modify" align="right" style={{marginTop: "auto", marginBottom: "auto"}}>
                                <Button onClick={() => handleModifyAction(location_id, prestataire_id, action)} style={{color: "#ffffff"}}><EditIcon style={{fill: "white"}}/></Button>
                            </Fab>

                            <Fab variant="extended" aria-label="delete" align="right" style={{backgroundColor:"#ff1515", marginTop: "auto", marginBottom: "auto"}}>
                                <Button onClick={() => handleDeleteAction(action)} style={{color: "#ffffff"}}><DeleteIcon style={{fill: "white"}}/></Button>
                            </Fab>
                        </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {action.nom}
                    </TableCell>
                    <TableCell>{recurenceToString(action.recurence_count, action.recurence_unit)}</TableCell>
                    <TableCell>{action.recurence_unit===0?"Non":"Oui"}</TableCell>
                    <TableCell align="right">{action.mail_notify}</TableCell>
                </TableRow>
            </React.Fragment>
        )

    }
    function RowPresta(props) {
        const { location_id, prestataire } = props;
        const [open, setOpen] = React.useState(false);
        const [actions, setactions] = React.useState([]);

        const classes = useRowStyles();
        return (
            <React.Fragment>
                <TableRow key={prestataire.id}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => {
                            if(!open) {
                                axios.get('https://api.coprotag.com/api/action?prestataire=' + prestataire.id, tokenConfig(store.getState)).then(res => {
                                    let a = []
                                    res.data.map((obj, index) => {
                                        a.push(<RowAction key={obj.id} idx={index} location_id={location_id} prestataire_id={prestataire.id} action={obj}/>)
                                    })
                                    setactions({loaded: true, datas: a})

                                });
                            }
                            setOpen(!open);
                        }}>
                            {open ? <CheckIcon color="secondary" /> : <CheckIcon color="primary" />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {prestataire.nom}
                    </TableCell>
                    <TableCell>{prestataire.siret}</TableCell>
                    <TableCell>{prestataire.action_count}</TableCell>
                    <TableCell align="right">{prestataire.last_anomalies}</TableCell>
                    <TableCell align="right">{prestataire.anomalies}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>

                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell>Nom de l'action à réaliser</TableCell>
                                            <TableCell>Intervalle de passage</TableCell>
                                            <TableCell >Récurence</TableCell>
                                            <TableCell align="right">Notification mail en cas d'anomalie</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {actions.datas}
                                    </TableBody>
                                </Table>
                            </Box>


                            <div style={{marginTop: "5px", marginBottom: "5px", textAlign: "center", width: "100%"}}>

                                <Fab color="primary" variant="extended" aria-label="add" align="right" style={{marginTop: "10px", marginBottom: "15px"}}>
                                    <Button onClick={() => handleClickActionOpen(location_id, prestataire.id)} style={{color: "#ffffff"}}><AddIcon style={{fill: "white"}}/> Ajouter une action</Button>
                                </Fab>
                                <Fab variant="extended" aria-label="delete" align="right" style={{backgroundColor:"#ff1515", marginTop: "10px", marginBottom: "15px"}}>
                                    <Button onClick={() => handleDeletePrestataire(prestataire.id, location_id)} style={{color: "#ffffff"}}><DeleteIcon style={{fill: "white"}}/>Supprimer prestataire {prestataire.nom}</Button>
                                </Fab>
                            </div>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )

    }

    function RowLocation(props) {
        const { location } = props;
        const [open, setOpen] = React.useState(false);
        //const [openAction, setopenAction] = React.useState(false);
        const [prestataires, setprestataire] = React.useState({data: [], loaded: false});

        const classes = useRowStyles();

        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => {
                            if(!open) {
                                axios.get('https://api.coprotag.com/api/prestataire?location=' + location.id, tokenConfig(store.getState)).then(res => {
                                    let a = []
                                    res.data.map((obj, index) => {
                                        a.push(<RowPresta key={obj.id} idx={index} location_id={location.id} prestataire={obj}/>)
                                    })
                                    setprestataire({loaded: true, datas: a})

                                });
                            }
                            setOpen(!open);
                        }}>
                            {open ? <ChevronDownIcon color="primary" /> : <ChevronRightIcon color="primary" />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {location.name}
                    </TableCell>
                    <TableCell align="right">{location.description}</TableCell>
                    <TableCell align="right">{location.enabled?<CheckIcon/>:<BlockIcon/>}</TableCell>
                    <TableCell align="right">{location.client_facturation_syndic?"Syndic":"Copropriété"}</TableCell>
                    <TableCell align="right">{location.tagCount}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <div style={{marginTop: "5px", marginBottom: "5px", width: "100%", textAlign:"center"}}>
                                    <Fab color="primary" variant="extended" aria-label="modify" align="center" style={{marginTop: "10px", marginBottom: "32px"}}>
                                        <Button onClick={() => handleModifyLocation(location)} style={{color: "#ffffff"}}><EditIcon style={{fill: "white"}}/>Modifier {location.name}</Button>
                                    </Fab>

                                    <Fab variant="extended" aria-label="delete" align="center" style={{backgroundColor:"#ff1515", marginTop: "10px", marginBottom: "32px"}}>
                                        <Button onClick={() => handleDeleteLocation(location.id)} style={{color: "#ffffff"}}><DeleteIcon style={{fill: "white"}}/>Supprimer {location.name}</Button>
                                    </Fab>
                                </div>
                                <div style={{marginTop: "5px", marginBottom: "20px"}}>Prestataires Copropriété {location.name}:</div>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell>Nom du prestataire</TableCell>
                                            <TableCell>n° SIRET</TableCell>
                                            <TableCell >Nombre d'actions</TableCell>
                                            <TableCell align="right">Nbre Anomalies 30 jours glissants</TableCell>
                                            <TableCell align="right">Nbre Anomalies total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {prestataires.datas}
                                    </TableBody>
                                </Table>
                            </Box>
                            <Fab color="primary" variant="extended" aria-label="add" align="right" style={{marginTop: "10px", marginBottom: "32px"}}>
                                <Button onClick={() => handleClickOpenPrestaDialog(location.id)} style={{color: "#ffffff"}}><AddIcon style={{fill: "white"}}/> Ajouter Prestataire</Button>
                            </Fab>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Mes Copropriétés</h4>
                        <p className={classes.cardCategoryWhite}>
                            Copropriétés
                        </p>
                    </CardHeader>
                    <CardBody>
                        {Location.loaded?
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell align="left">Nom de la Copropriété</TableCell>
                                        <TableCell align="right">Description</TableCell>
                                        <TableCell align="right">Actif</TableCell>
                                        <TableCell align="right">Facturation TAGs</TableCell>
                                        <TableCell align="right">Nombre de TAGs associés</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Location.datas}
                                </TableBody>
                            </Table>
                        </TableContainer>:
                            "Loading..."}
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>

            <Fab color="primary" variant="extended" aria-label="add" align="right">
                <Button onClick={handleClickOpen} style={{color: "#ffffff"}}><AddIcon style={{fill: "white"}}/> Créer une Copropriété</Button>
            </Fab>
            {/* Creation / modification Copro */}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{locForm.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Informations concernant la copropriété
                    </DialogContentText>

                    <FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nom de la copropriété"
                        type="text"
                        fullWidth
                        name="name"
                        value={locForm.name}
                        onChange={handleChange}
                    />
                    <DialogContentText>
                        -
                    </DialogContentText>
                    <TextField
                        id="descre"
                        label="Description"
                        type="text"
                        fullWidth
                        name="descr"
                        value={locForm.descr}
                        onChange={handleChange}
                    />

                        <DialogContentText>
                        </DialogContentText>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleValidate} color="primary">
                        Creer la copropriété
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Creation / modification Prestataire */}
            <Dialog open={openPrestaDialog} onClose={handleClosePresta} aria-labelledby="form-dialog-title">
                <DialogTitle id="form_add_presta">{prestaForm.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Informations concernant le prestataire
                    </DialogContentText>

                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="siren"
                            label="SIREN/SIRET du prestataire"
                            type="text"
                            fullWidth
                            name="siren"
                            value={prestaForm.siren}
                            error={prestaForm.siren_error}
                            onChange={handlePresta}
                        />
                        <DialogContentText>
                            (Recommandé)
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Ou Nom du prestaraire"
                            type="text"
                            fullWidth
                            name="name"
                            value={prestaForm.name}
                            disabled={prestaForm.name_lock}
                            onChange={handlePresta}
                        />
                        <DialogContentText>
                            Si vous ne connaissez pas le SIREN
                        </DialogContentText>


                        <Select
                            labelId="demo-simple-select-label"
                            id="type_presta"
                            name="type_presta"
                            error={prestaForm.type_presta_error}
                            value={prestaForm.type_presta}
                            onChange={handlePresta}
                        >
                            <MenuItem value={1}>Entretien / Nettoyage</MenuItem>
                            <MenuItem value={2}>Espaces Verts</MenuItem>
                            <MenuItem value={3}>Technique / Maintenance</MenuItem>
                            <MenuItem value={4}>Syndicat de Copropriété</MenuItem>
                            <MenuItem value={5}>Sécurité</MenuItem>
                            <MenuItem value={0}>Autre</MenuItem>
                        </Select>
                        <DialogContentText>
                            {prestaForm.type_presta_error?"Veuillez selectionner un type de prestataire":"-"}
                        </DialogContentText>

                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePresta} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleValidatePresta} color="primary">
                        Creer le prestataire
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Creation / modification Action */}
            <Dialog open={openActionDialog} onClose={handleCloseAction} aria-labelledby="form-dialog-title">
                <DialogTitle id="form_add_action">{actionForm.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Informations concernant l'action
                    </DialogContentText>

                    <FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="nom"
                            label="Nom de l'action"
                            type="text"
                            fullWidth
                            name="nom"
                            value={actionForm.nom}
                            error={actionForm.nom_error}
                            onChange={handleAction}
                        />
                        <DialogContentText>
                            -
                        </DialogContentText>
                        <Select
                            labelId="demo-simple-select-label"
                            id="select_recurrence"
                            name="select_recurrence"
                            error={actionForm.type_recurrence_error}
                            value={actionForm.type_recurrence}
                            onChange={handleAction}
                        >
                            <MenuItem value={0}>Pas de récurrence</MenuItem>
                            <MenuItem value={1}>Jour (n'importe quel TAG)</MenuItem>
                            <MenuItem value={2}>Semaine (n'importe quel TAG)</MenuItem>
                            <MenuItem value={3}>Quinzaine (n'importe quel TAG)</MenuItem>
                            <MenuItem value={4}>Mois (n'importe quel TAG)</MenuItem>
                            <MenuItem value={5}>Trimestre (n'importe quel TAG)</MenuItem>
                            <MenuItem value={6}>Semestre (n'importe quel TAG)</MenuItem>
                            <MenuItem value={7}>Annuel (n'importe quel TAG)</MenuItem>

                            <MenuItem value={8}>Jour (chaque TAG)</MenuItem>
                            <MenuItem value={9}>Semaine (chaque TAG)</MenuItem>
                            <MenuItem value={10}>Quinzaine (chaque TAG)</MenuItem>
                            <MenuItem value={11}>Mois (chaque TAG)</MenuItem>
                            <MenuItem value={12}>Trimestre (chaque TAG)</MenuItem>
                            <MenuItem value={13}>Semestre (chaque TAG)</MenuItem>
                            <MenuItem value={14}>Annuel (chaque TAG)</MenuItem>
                        </Select>
                        <DialogContentText>
                            {actionForm.type_recurrence_error?"Veuillez selectionner la récurrence souhaité":"-"}
                        </DialogContentText>

                        <TextField
                            margin="dense"
                            id="qty"
                            label="Nombre de passages"
                            type="number"
                            fullWidth
                            name="qty"
                            value={actionForm.qty}
                            error={actionForm.qty_error}
                            disabled={actionForm.qty_disabled}
                            onChange={handleAction}
                        />
                        <DialogContentText>
                            -
                        </DialogContentText>

                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAction} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleValidateAction} color="primary">
                        Creer l'action
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
