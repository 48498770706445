import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "../../app/components/Card";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import rayures from "../../../assets/img/rayures.png";
import surf_full from "../../../assets/img/Surf_full.jpg";
import head_forfaits from "../../../assets/img/head_forfaits.png";
import qrcode from "../../../assets/img/qr_code_ct.jpg";
import sticker1 from "../../../assets/img/Sticker_1.jpg";
import sticker2 from "../../../assets/img/Sticker_2.jpg";
import sticker3 from "../../../assets/img/Sticker_3.jpg";
import surf from "../../../assets/img/Surf.jpg";

import Image from 'material-ui-image'
class Forfaits extends React.Component {
    render() {
        return(
            <React.Fragment>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={9} style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                        <Image src={head_forfaits} aspectRatio={200/76} />
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={12} sm={5} md={3} style={{padding: "30px"}}>
                        <Card style={{backgroundColor: "#0094b2", height: "100%", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{color: "#ffffff", fontSize: "32px", marginLeft: "15%"}}><span style={{fontSize: "48px"}}>12</span>,99€ / mois HT</p>
                            <p style={{color: "#000000", fontSize: "26px", marginLeft: "15%"}}>15,59€/mois TTC /<span style={{color: "#ffffff"}}>TAG*</span></p>
                            <div>
                                <img src={qrcode} height="100px" width="100px" style={{float:"left", marginLeft: "15%"}}/>
                                <p style={{color: "#000000", fontSize: "32px", marginLeft: "30px",float:"left"}}><span style={{color: "#ffffff", height: "100%"}}>1</span> à <span style={{color: "#ffffff", height: "100%"}}>5</span> TAGS</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={5} md={3} style={{padding: "30px"}}>
                        <Card style={{backgroundColor: "#0094b2", height: "100%", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{color: "#ffffff", fontSize: "32px", marginLeft: "15%"}}><span style={{fontSize: "48px"}}>10</span>,95€ / mois HT</p>
                            <p style={{color: "#000000", fontSize: "26px", marginLeft: "15%"}}>13,14€/mois TTC /<span style={{color: "#ffffff"}}>TAG*</span></p>
                            <div>
                                <img src={qrcode} height="100px" width="100px" style={{float:"left", marginLeft: "15%"}}/>
                                <p style={{color: "#000000", fontSize: "32px", marginLeft: "30px",float:"left"}}><span style={{color: "#ffffff", height: "100%"}}>6</span> à <span style={{color: "#ffffff", height: "100%"}}>19</span> TAGS</p>
                            </div>
                        </Card>
                    </Grid>


                    <Grid item xs={12} sm={5} md={3} style={{padding: "30px"}}>
                        <Card style={{backgroundColor: "#0094b2", height: "100%", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{color: "#ffffff", fontSize: "32px", marginLeft: "15%"}}><span style={{fontSize: "48px"}}>9</span>,95€ / mois HT</p>
                            <p style={{color: "#000000", fontSize: "26px", marginLeft: "15%"}}>11,94€/mois TTC /<span style={{color: "#ffffff"}}>TAG*</span></p>
                            <div>
                                <img src={qrcode} height="100px" width="100px" style={{float:"left", marginLeft: "15%"}}/>
                                <p style={{color: "#000000", fontSize: "32px", marginLeft: "20px",float:"left"}}><span style={{color: "#ffffff", height: "100%"}}>20</span> à <span style={{color: "#ffffff", height: "100%"}}>100</span> TAGS</p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={5} md={3} style={{padding: "30px"}}>
                        <Card style={{backgroundColor: "#0094b2", height: "100%", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{color: "#ffffff", fontSize: "32px", marginLeft: "8%"}}>FORFAIT SUR MESURE</p>
                            <p style={{color: "#000000", fontSize: "26px", marginLeft: "15%"}}>Merci de contacter notre<br/><span style={{color:"#ffffff"}}>service commercial<br/>au 04 81 16 09 06<br/>ou par email</span></p>
                            <Button style={{color: "#0094b2", textAlign: "center", backgroundColor: "#ffffff", position: "absolute",top: "265px", right: "30%"}} component={Link} to="/forfaits">DEMANDER UN FORFAIT<br/>SUR MESURE</Button>

                        </Card>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <div style={{backgroundColor: "#dedede", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{textAlign: "center", fontSize: "38px"}}><b>EN OPTION : <span style={{color: "#0094b2"}}>PERSONNALISATION</span><br/></b>
                                <span style={{textAlign: "center", fontSize: "32px", color: "#0094b2"}}>Votre TAG aux couleurs de votre enseigne</span>
                            </p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sm={5} md={3} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <div>
                            <p style={{textAlign: "center", fontSize: "38px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>+ 2€ / mois HT*</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5} md={3} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <div>
                            <p style={{textAlign: "center", fontSize: "38px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>+ 2€ / mois HT*</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5} md={3} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <div>
                            <p style={{textAlign: "center", fontSize: "38px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>+ 1€ / mois HT*</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5} md={3} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <div>
                            <p style={{textAlign: "center", fontSize: "38px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>Inclus</p>
                        </div>
                    </Grid>
                </Grid>


                <Grid container>
                    <Grid item xs={6} sm={6} md={6} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <Card >
                            <Image src={surf_full} aspectRatio={877/468} alt style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}/>
                        </Card>

                    </Grid>

                    <Grid item xs={0} sm={0} md={1} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    </Grid>

                    <Grid item xs={6} sm={6} md={5} style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <Card>
                            <img height="468px" alt="Visionnez notre démo" src={rayures}/>
                            <p style={{position: "absolute", fontSize: "32px", textAlign:"right", marginRight:"110px", marginLeft:"40px"}}>PERSONNALISATION<br/>
                                <span style={{textAlign:"right", fontSize: "32px"}}><span style={{fontFamily: "bauserif"}}>Copro<span style={{color: "#0094b2"}}>TAG</span></span> vous propose de<br/>personnaliser vos Tags en cohérence<br/>avec votre charte graphique<br/></span>

                                <span style={{fontSize: "22px"}}><br/>
                                    Une <span style={{color: "#0094b2"}}>communication de votre enseigne</span> pour gagner en visibilité auprès des résidents et copropriétaires.
Intégrez votre logo, vos images, vos codes couleurs ainsi qu'une adresse de connexion à votre nom.
                                </span>
                            </p>
                        </Card>
                    </Grid>
                </Grid>


                <Grid container>

                    <Grid item xs={12} sm={5} md={4} style={{padding: "30px"}}>
                        <Card>
                            <Image src={sticker1} aspectRatio={468/468} alt style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}/>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={5} md={4} style={{padding: "30px"}}>
                        <Card>
                            <Image src={sticker2} aspectRatio={468/468} alt style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}/>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={5} md={4} style={{padding: "30px"}}>
                        <Card>
                            <Image src={sticker3} aspectRatio={468/468} alt style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}/>
                        </Card>
                    </Grid>
                </Grid>

            </React.Fragment>)
    }
}

export default Forfaits;