import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {loadUser, login} from "../../actions/authentication";
import store from "../../store";



const PrivateRoute = ({component: Component, auth, orRedirectTo, ...rest}) => {
    return (
        <Route {...rest} render={props => {
            if(auth.isLoading) {
                return <h1>Loading...</h1>
            } else if(!auth.isAuthenticated) {
                console.log("You're not login, redirect to "  + orRedirectTo);
                return <Redirect to={orRedirectTo} />;
            } else {
                console.log("You're login, Access granted "  + props.name);
                return <Component {...props}/>;
            }}}/>
    )
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {login})(PrivateRoute);