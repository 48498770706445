import React from "react";

import Card from '../../app/components/Card'
import Grid from "@material-ui/core/Grid";
import qrcode from "../../../assets/img/qr_code_ct.jpg";
import Image from "material-ui-image";
import head_contact from "../../../assets/img/head_contact.png";


class Contact extends React.Component {
    render() {
        return(
            <React.Fragment>

                <Grid container justify="center" style={{marginTop: "60px"}}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Image src={head_contact} aspectRatio={200/76} style={{boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}/>
                    </Grid>
                </Grid>

                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={4} style={{padding: "30px"}}>
                        <Card style={{backgroundColor: "#0094b2", height: "100%", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{color: "#ffffff", fontSize: "32px", marginLeft: "15%"}}>Par<span style={{fontSize: "48px"}}> courriel</span></p>
                            <p style={{color: "#000000", fontSize: "26px", marginLeft: "15%"}}>Notre <span style={{color: "#ffffff"}}>service commercial</span></p>
                            <div>
                                <img src={qrcode} height="100px" width="100px" style={{float:"left", marginLeft: "15%"}}/>
                                <p style={{color: "#000000", fontSize: "18px", marginLeft: "30px",float:"left"}}><a style={{color: "#ffffff"}} href="mailto:Service.Client@CoproTAG.com">Service.Client@CoproTAG.com</a></p>
                            </div>


                            <p style={{color: "#000000", fontSize: "26px", marginLeft: "15%"}}>Notre <span style={{color: "#ffffff"}}>service technique</span></p>
                            <div>
                                <img src={qrcode} height="100px" width="100px" style={{float:"left", marginLeft: "15%"}}/>
                                <p style={{color: "#000000", fontSize: "18px", marginLeft: "30px",float:"left"}}><a style={{color: "#ffffff"}} href="mailto:Service.Technique@CoproTAG.com">Service.Technique@CoproTAG.com</a></p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} style={{padding: "30px"}}>
                        <Card style={{backgroundColor: "#0094b2", height: "100%", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{color: "#ffffff", fontSize: "32px", marginLeft: "15%"}}>Par<span style={{fontSize: "48px"}}> téléphone</span></p>
                            <p style={{color: "#000000", fontSize: "26px", marginLeft: "15%"}}>Nos bureaux sont ouverts du<span style={{color: "#ffffff"}}> Lundi au Vendredi de 8h30 à 18h</span>
                            </p>
                            <div>
                                <img src={qrcode} height="100px" width="100px" style={{float:"left", marginLeft: "15%"}}/>
                                <p style={{color: "#000000", fontSize: "26px", marginLeft: "30px",float:"left"}}><a style={{color: "#ffffff"}} href="tel:+334 81 16 09 06">04 81 16 09 06</a></p>
                            </div>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} style={{padding: "30px"}}>
                        <Card style={{backgroundColor: "#0094b2", height: "100%", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"}}>
                            <p style={{color: "#ffffff", fontSize: "32px", marginLeft: "15%"}}>Par<span style={{fontSize: "48px"}}> courrier</span></p>
                            <div>
                                <img src={qrcode} height="100px" width="100px" style={{float:"left", marginTop: "32px", marginLeft: "15%"}}/>
                                <p style={{color: "#000000", fontSize: "26px", marginLeft: "30px",float:"left"}}>CoproTAG<br/>34 rue du Docteur Abel<br/>26000 Valence</p>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>)
    }
}

export default Contact;