import React from "react";

import style from "./styles";
import Typography from "@material-ui/core/Typography";
import Modal from '@material-ui/core/Modal';

import Grid from "@material-ui/core/Grid";

import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {login} from '../../../../actions/authentication';

import { useHistory } from "react-router-dom";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function Login(props) {
    var classes = style();
    const classeStyle = useStyles();
    const { onClose, open, redirect } = props;
    const [modalStyle] = React.useState(getModalStyle);

    let history = useHistory()

    const state = React.useState({
        email: '',
        password: ''
    });

    const  propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool
    }

    const handleClose = () => {
        onClose(true);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        props.login(state.email, state.password);
        onClose(true);
        if(redirect) {
            setTimeout(() => {
                history.push(redirect);
            }, 1000);
        }

    }
    function onChange(e) {
        switch (e.target.name) {
            case 'email':
                state.email = e.target.value;
                break;
            case 'password':
                state.password = e.target.value;
                break
        }
    }
    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Login"
                aria-describedby="Login Modal"
            >
                <div className={classeStyle.paper} style={modalStyle}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Avatar className={classes.avatar}>
                                        <LockOutlinedIcon />
                                    </Avatar>
                                </Grid>
                                <Grid item xs={9} >
                                    <Typography component="h1" variant="h5">
                                        me connecter
                                    </Typography>
                                </Grid>
                            </Grid>
                            <form className={classes.form} noValidate onSubmit={onSubmit}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Mon adresse email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={state.email}
                                    onChange={onChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Mon mot de passe"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={state.password}
                                    onChange={onChange}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Connexion
                                </Button>

                                <Grid container>
                                    <Grid item>
                                        <br/>
                                        <Link  href="#" variant="body2">
                                            Mot de passe oublié ?
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item>
                                        <Link href="/register" variant="body2">
                                            {"Pas encore de compte ? Créez en un !"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Container>
                </div>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps, {login})(Login);