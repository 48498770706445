/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
// @material-ui/core components
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../NavbarLinks/";
import style from "../sidebar/styles";
import {connect} from "react-redux";
import {logout} from "../../../../actions/authentication";

function Sidebar(props) {
    const classes = style();
    const { isAuthenticated, user } = props.auth;

    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }
    const { logo, image, routes } = props;
    var links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                if((isAuthenticated === false)){
                    return false;
                }
                let listItemClasses;
                listItemClasses = classNames({
                    [" " + classes["sidefocused"]]: activeRoute("/app" + prop.path)
                });

                const whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute("/app" + prop.path)
                });
                return (
                    <NavLink
                        to={"/app" + prop.path}
                        className={classes.item}
                        activeClassName="active"
                        key={key}
                    >
                        <ListItem button className={classes.itemLink + listItemClasses}>
                            {typeof prop.icon === "string" ? (
                                <Icon className={classes.itemIcon + whiteFontClasses} >
                                    {prop.icon}
                                </Icon>
                            ) : (
                                <prop.icon
                                    className={classes.itemIcon + whiteFontClasses}
                                />
                            )}
                            <ListItemText
                                primary={prop.name}
                                className={classes.itemText + whiteFontClasses}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );
    var brand = (
        <div className={classes.logo}>
            <Link to="/" className={classes.logoLink}>
                <div className={classes.logoImage}>
                    <img src={logo} alt="CoproTAG" className={classes.img} />
                </div>
            </Link>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    open={props.open}
                    anchor="left"
                    classes={{paper: classes.drawerPaper}}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        {links}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer anchor="left" variant="permanent" open classes={{paper: classes.drawerPaper}}>
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    <div className={classes.background} />
                </Drawer>
            </Hidden>
        </div>
    );
}


const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {logout})(Sidebar);