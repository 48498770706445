import React from "react";
import Grid from "@material-ui/core/Grid";
import Image from "material-ui-image";
import head_expertise from "../../../assets/img/head_expertise.png";
import qui_sommes_nous from '../../../assets/img/qui_sommes_nous.png';
import ssl_config from "../../../assets/img/ssl_config.png";



class Expertise extends React.Component {
    render() {
        return(
            <React.Fragment>
                <Grid container justify="center" style={{marginTop: "60px"}}>
                    <Grid item xs={12} sm={12} md={11}>
                        <Image src={head_expertise} aspectRatio={1557/864} alt="Dashboard révélant les données statistiques recueillies : Statut des QR codes, remontée d’information relatives aux prestataires, anomalies détectées..."/>
                    </Grid>
                </Grid>
                <Grid container justify="center" style={{marginTop: "60px"}}>
                    <Grid item xs={12} sm={12} md={8}>
                        <h1 style={{marginLeft: "100px", color: "#0094b2", fontSize: ""}}>PLATEFORME sécurisée</h1>
                        <p style={{
                            fontSize: "x-large",
                            textAlign: "right",
                            padding: "50px",
                            marginTop: "30px"}}>
                            Corpo<span style={{color: "#0094b2"}}>TAG.com vous offre une plateforme hautement sécurisée.<br/>
                            Notre équipe d'ingénieurs met en place une infrastructure utilisant les technologies les plus robustes du moment<br/>
                            Des tests de sécurité sont très régulièrement mis en place afin de garantir une confiance optimale<br/>
                            en la confidentialité et la protection de vos données.<br/><br/>
                        </span><span>Aucun site externe, tracker ou cookie est utilisé et <span style={{color: "#0094b2"}}>VOS</span> données sont hébergées en France</span>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Image src={ssl_config} aspectRatio={1412/867} alt=""/>
                    </Grid>

                        <Grid container justify="center" style={{marginTop: "60px"}}>
                            <Grid item xs={12} sm={12} md={11}>
                                <Image src={qui_sommes_nous} aspectRatio={1531/650} alt="Qui sommes-nous ?
                                Experts de proximité, nous offrons un service efficace aux syndics et Copropriétaires : un retour d’information direct sur les prestations effectuées dans les copropriétés.
                                Attentif et performant, notre service développement saura adapter nos outils à vos problématiques spécifiques."/>
                            </Grid>
                        </Grid>
                </Grid>

            </React.Fragment>)
    }
}

export default Expertise;