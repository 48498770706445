import {makeStyles} from "@material-ui/styles";

const style = theme => ({
    root: {
        flexGrow: 1,

        marginRight: theme.spacing(10),
    },
    menuButton: {
        marginRight: theme.spacing(4),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "white",
        /*boxShadow: "none",*/
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100wh",
        paddingTop: "10px",
        zIndex: "1029",
        color: "#000",
        border: "0",
        borderRadius: "3px",
        padding: "10px 0",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block"
    },
});

export default makeStyles(style);