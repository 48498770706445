import makeStyles from "@material-ui/core/styles/makeStyles";

const style = theme => ({
    warningTableHeader: {
    color: theme.palette.warning.main
  },
  primaryTableHeader: {
    color: theme.palette.primary.main
  },
  dangerTableHeader: {
    color: theme.palette.error.main
  },
  successTableHeader: {
    color: theme.palette.success.main
  },
  infoTableHeader: {
    color: theme.palette.success.dark
  },
  pinkTableHeader: {
    color: "#D6002A"
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse"
  },
  tableHeadCell: {
    color: "inherit",
    //...defaultFont,
    "&, &$tableCell": {
      fontSize: "1em"
    }
  },
  tableCell: {
    //...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 6px",
    verticalAlign: "middle",
    fontSize: "0.8125rem"
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(2),
    overflowX: "auto",
    minHeight: "430px"
  },
  tableHeadRow: {
    height: "56px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle"
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle"
  }
});

export default makeStyles(style);