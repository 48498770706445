import React from "react";

import {Link, Redirect} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Image from "material-ui-image";
import ReactPlayer from 'react-player/lazy'
import video_fr_vp9 from "../../../assets/video/demo_fr.webm"
import video_fr_265 from "../../../assets/video/demo_fr_265.mp4"
import video_fr_mp4 from "../../../assets/video/demo_fr.mp4"
import GridItem from "../../app/components/Grid/Item";
import GridContainer from "../../app/components/Grid/Container";
import Box from "@material-ui/core/Box";

class Demo extends React.Component {
    render() {
        return(
            <React.Fragment>
                <Grid container justify="center" >
                    <Grid item xs={12} sm={12} md={12} style={{paddingTop: '70px'}}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <ReactPlayer
                                className='react-player'
                                url={[{src: video_fr_vp9, type: "video/webm", codecs: "vp9,vorbis"}, {src: video_fr_265, type: "video/mp4"}, {src: video_fr_mp4, type: "video/mp4"}]}

                                controls
                                preload

                                playsinline

                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            poster: 'https://api.coprotag.com/logo?baseline=true&animated=true'
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                {/*} <div style={{ position: "relative", paddingTop: "13rem"}}>
                    <ReactPlayer
                        className='react-player'
                        url={[{src: video_fr_vp9, type: "video/webm", codecs: "vp9,vorbis"}, {src: video_fr_mp4, type: "video/mp4"}]}
                        width='100%'
                        height='100%'
                        controls
                        config={{
                            file: {
                                attributes: {
                                    preload: 'auto',
                                    controlsList: 'nodownload'
                                }
                            }
                        }}
                    />
                </div> */}
                {/*<Grid container justify="center" style={{marginTop:"10vh"}}>
                    <GridItem xs={12} sm={12} md={12}>
                        <div>
                        <ReactPlayer url={[{src: video_fr_vp9, type: "video/webm"}, {src: video_fr_mp4, type: "video/mp4"}]} config={{
                            file: {
                                attributes: {

                                    poster:"https://api.coprotag.com/logo?baseline=true&animated=true",
                                    preload: 'auto',
                                    controlsList: 'nodownload'
                                }
                            }
                        }}
                                     height='70%' controls width={"70%"} />
                        </div>
                    </GridItem>
                </Grid>*/}
            </React.Fragment>)
    }
}

export default Demo;