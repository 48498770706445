import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';

import store from '../store';
import PrivateRoute from "../components/privateRoute";


/* MaterialUI Stuffs */
import { MuiThemeProvider } from '@material-ui/core/styles';

/* Import layouts pages*/
import HomeLayout from './home'
import AppLayout from "./app";
import ScanLayout from "./scan";

/* Import Theme */
import theme from '../theme'

/*  Import Fonts */
import '../assets/fonts/AvenirLTStd-Roman.otf';
import '../assets/fonts/bauserif.ttf';
import '../fonts.css'


class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <Router>
                        <Switch>
                            <PrivateRoute path="/app" component={AppLayout} />
                            <Route path="/tag/:tagID" component={ScanLayout}/>
                            <Route path="/"  component={HomeLayout} />
                        </Switch>
                    </Router>
                </MuiThemeProvider>
            </Provider>
        );
    }
}

export default App;