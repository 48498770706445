import React from "react";
import {Link} from 'react-router-dom'
import PropTypes from "prop-types";
import {connect} from "react-redux";


import Login from "../login";

/* Material UI Stuffs */
import {AppBar as MUIAppBar} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import styles from './styles'

function AppBar(props) {
    const classes = styles();
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <MUIAppBar className={classes.appBar} style={{alignItems: 'center'}}>
                <Grid container justify="center">
                    <Link to="/"><img alt="" style={{height: "15vh"}} src="https://api.coprotag.com/logo?baseline=true&animated=true"/></Link>
                </Grid>
                <Grid container justify="center">
                <Toolbar>
                    <div>
                    <Button className={classes.menuButton} style={{textTransform: "none"}} component={Link} to="/"><Typography style={{fontWeight: "bold"}}>Accueil</Typography></Button>
                        <Button className={classes.menuButton}style={{textTransform: "none"}} component={Link} to="/concept"><Typography style={{fontWeight: "bold"}}>Concept</Typography></Button>
                        <Button className={classes.menuButton}style={{textTransform: "none"}} component={Link} to="/expertise"><Typography style={{fontWeight: "bold"}}>Expertise</Typography></Button>
                    <Button className={classes.menuButton}style={{textTransform: "none"}} component={Link} to="/forfaits"><Typography style={{fontWeight: "bold"}}>Forfaits</Typography></Button>
                        {<Button className={classes.menuButton}style={{textTransform: "none"}} component={Link} to="/video"><Typography style={{fontWeight: "bold"}}>Démo Vidéo</Typography></Button>}
                    <Button className={classes.menuButton}style={{textTransform: "none"}} component={Link} to="/contact"><Typography style={{fontWeight: "bold"}}>Contact</Typography></Button>
                    {
                        props.auth.isLoading?
                            <Button className={classes.menuButton} style={{textTransform: "none"}}><Typography style={{fontWeight: "bold"}} color="primary">Chargement...</Typography></Button>:
                            props.auth.isAuthenticated?
                                    <Button className={classes.menuButton} style={{textTransform: "none"}} component={Link} to="/app" ><Typography style={{fontWeight: "bold"}} color="primary">{props.auth.user.get_full_name}</Typography></Button>:
                                    <Button className={classes.menuButton} style={{textTransform: "none"}} onClick={handleClickOpen} ><Typography style={{fontWeight: "bold"}} color="primary">Se connecter</Typography></Button>
                    }
                    </div>
                </Toolbar>
                </Grid>
            </MUIAppBar>
            <Login open={open} redirect="/app" onClose={handleClose}/>
        </React.Fragment>
    )

}
AppBar.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});


export default connect(mapStateToProps)(AppBar);